import { Comment } from './../../../common/types';
import {
  Component,
  ViewChild,
  AfterViewInit
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NgForm,
  ValidationErrors
} from '@angular/forms';

import {
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  UserService,
  UtilsService,
} from '@pinacono/common';

import {
  InterpolatbleErrorMessage,
  ModalComponent,
  UIService,
} from '@pinacono/ui';

import {
  Editors,
  Formatters,
  OnEventArgs,
  SlickGrid
} from '@slickgrid-universal/common';

import {
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  ButtonsFormatter,
  DataGridButton,
  DatetimeMomentFormatter,
  GraphQLServerService,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { DocLibService } from 'src/app/modules/documents/doclib.service';

import { ProjectLibService } from '../projects.service';
import {
  ProjectDrawing,
  ProjectDocument,
  ProjectHandOver,
  HandOverProjectDocumentRevision,
  HandOverProjectDrawingRevision,
  ProjectDrawingRevision,
} from '../types';

//import { TEST_DATA } from 'src/moc-data/projects';

@Component({
  selector: 'page-projlib-edit',
  templateUrl: 'edit.html',
  styleUrls: [ 'edit.scss' ]
})
export class ProjectLibHandOverEditPage extends BasePageComponent implements AfterViewInit {

  // view child
  @ViewChild('mainForm') mainForm!: NgForm;
  @ViewChild('selectDocumentsModal') selectDocumentsModal!: ModalComponent;
  @ViewChild('documentViewDialog') documentViewDialog!: ModalComponent;
  @ViewChild('drawingViewDialog')  drawingViewDialog!: ModalComponent;

  // templates / flags
  public selectingDocumentsType: 'document'|'drawing'|null = null;
  public selectedIDs: number[] = [];

  public selectedDocument: ProjectDocument|null = null;
  public selectedDrawing: ProjectDrawing|null = null;

  // data
  protected project_id: number|null = null;
  protected project_documents: ProjectDocument[] = [];
  protected project_drawings: ProjectDrawing[] = [];

  public handover: ProjectHandOver;
  public errors: {
    [name: string]: InterpolatbleErrorMessage | InterpolatbleErrorMessage[] | string | string[];
  } | ValidationErrors = {};

  // ----------------------------------------------------
  // -- life cycle
  // ----------------------------------------------------

  // constructor
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: ProjectLibService,
    public docapi: DocLibService,
    protected server: ServerService,
    protected user: UserService,
    protected utils: UtilsService,
    protected graphqlServer: GraphQLServerService,
  ) {
    super(router, activatedRoute);
    this.handover  = this.api.createProjectHandOver();
  }

  public ngAfterViewInit(): void {
  }

  protected override async loadData(): Promise<any> {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if ( id === null ) {
      this.nav.goto('/project/browse/default');
      return;
    }

    // reset form
    this.clearDirty();

    // reset grid
    this.projectDocumentGridOptions = null;
    this.projectDrawingGridOptions  = null;

    this.project_id = parseInt(this.activatedRoute.snapshot.queryParamMap.get('project_id') || '');
    const nid: number = parseInt(id);

    if ( isNaN(nid) ) {

      if ( isNaN(this.project_id) ) {
        this.ui.alert('Project ID is required!');
        this.nav.back('/project/browse/default');
        return;
      }

      // create new handover

      try {
        const res: object_t = await this.server.show('projects', this.project_id, {
          with: [ 'documentable' ].join(','),
          appends: [  ].join(',')
        });

        this.handover = this.api.createProjectHandOver({
          project_id: this.project_id,
          project: this.api.createProject(res)
        });
      }
      catch ( e: unknown ) {
        await this.ui.alert('Error loading Project id {{ id }} {{ error }}', {
          id: id,
          error: (e as Error).message
        });
        this.back();
      }
    }
    else {
      this.handover = this.api.createProjectHandOver({
        project_id: this.project_id
      });

      try {
        const res: object_t = await this.server.show('project_handovers', nid, {
          with: [
            'project',
            'project.internal_project_roles',
            'author', 'approver',
            'project',
            'comments'
          ].join(','),
          appends: [
            //
          ].join(','),
        });

        this.handover = this.api.createProjectHandOver(res);
        this.project_id = this.handover.project_id;
      }
      catch ( e: unknown ){
        await this.ui.alert('Error loading Project hand over id {{ id }} {{ error }}', {
          id: id,
          error: (e as Error).message
        });
        this.back();
      }
    }

    this.initProjectHandOverDocumentsGrid();
    this.initProjectHandOverDrawingsGrid();

    this.initProjectDocumentsGrid();
    this.initProjectDrawingsGrid();
  }

  // ----------------------------------------------------
  // -- common actions
  // ----------------------------------------------------

  /**
   * return to previous page
   */
   public back() {
    this.nav.pop(false, '/project/browse/mine');
  }

  /**
   * delete this document
   */
  public async delete() {
    if ( this.handover.id && await this.ui.confirm('Delete this project handover?') ) {
      await this.server.destroy('project_handovers', this.handover.id!);
      this.back();
    };
  }

  /**
   * save this document
   */
  public async save(save_docs: boolean = true) {
    if ( ! await this.validate() ) {
      const errors = Object.keys(this.errors);
      console.log(errors)
      this.ui.alert('Some value is invalid. Please check!');
      return;
    }

    if ( ! await this.ui.confirm('Save this project hand over?') ) {
      return;
    }

    if ( ! this.handover.id ) {
      const res: object_t = await this.server.create('project_handovers', {
        project_id: this.project_id,
        author_id: this.session.currentUser?.id,
        status: 'draft'
      });
      this.handover = this.api.createProjectHandOver(res);
    }

    if ( save_docs) {
      this.saveProjectDocuments();
      this.saveProjectDrawings();
      await this.loadData();
    }
  }

  /**
   * save project documents to handover
   */
  public async saveProjectDocuments() {
    this.handover.documents.forEach( async (d: HandOverProjectDocumentRevision) => {
      this.server.update('project_document_handovers', d.id!, {
        handover_id: this.handover.id,
        revision_id: d.revision.id,
        accepted: d.accepted,
        soft_copy: d.soft_copy,
        hard_copies: d.hard_copies,
        comment: d.comment
      });
    });
  }

  /**
   * save project drawing to handover
   */
  public async saveProjectDrawings() {
    this.handover.drawings.forEach( async (d: HandOverProjectDrawingRevision) => {
      await this.server.update('project_drawing_handovers', d.id!, {
        handover_id: this.handover.id,
        revision_id: d.revision.id,
        accepted: d.accepted,
        soft_copy: d.soft_copy,
        hard_copies: d.hard_copies,
        comment: d.comment
      });
    });
  }

  public async viewDocument(doc: ProjectDocument) {
    this.selectedDocument = this.api.createProjectDocument(await this.server.show('projects/documents', doc.id!, {
      with: 'revisions,revisions.uploader,revisions.reviewer,revisions.approver'
    }));
    this.documentViewDialog.show();
  }

  public async viewDrawing(drawing: ProjectDrawing) {
    const res = await this.server.show('projects/drawings', drawing.id!, {
      //with: 'reviewer,approver,drawings',
      with: 'drawings',
      appends: 'masters'
    });
    this.selectedDrawing = this.api.createProjectDrawing(res);
    this.selectedDrawing.revisions.forEach( async (d: ProjectDrawingRevision) => {
      if ( d.drawing_id ) {
        d.masters = await this.api.loadMasters(d.drawing_id, 'drawing');
      }
    });
    this.drawingViewDialog.show();
  }

  public async addHandOverDocuments() {
    this.selectDocumentsModal.hide();

    let redirect: boolean = false;

    if ( ! this.handover.id ) {
      // create new handover document, if not created
      await this.save(false); // we will redirect after saving handover documents and drawings
      redirect = true;
    }

    if ( this.selectingDocumentsType == 'document' ) {

      /*
      this.removedDocuments.forEach( async (id: number) => {
        const i = this.handover.documents.findIndex( (d: HandOverProjectDocumentRevision) => d.revision.id == id );
        if ( i >= 0 ) {
          await this.server.destroy('project_document_handovers', this.handover.documents[i].id!);
          this.handover.documents.splice(i, 1);
          this.markDirty();
        }
      });
      */

      this.addedDocuments.forEach( async (id: number) => {
        const i = this.handover.documents.findIndex( (d: HandOverProjectDocumentRevision) => d.revision.id == id );
        if ( i < 0 ) {
          const res: object_t = await this.server.create('project_document_handovers', {
            handover_id: this.handover.id,
            revision_id: id
          });
          this.markDirty();
        }
      });

      //this.removedDocuments.clear();
      this.addedDocuments.clear();
      ! redirect && this.projectHandOverDocumentGridInstance?.extensionService.refreshBackendDataset();

    }
    else if ( this.selectingDocumentsType == 'drawing' ) {

      /*
      this.removedDrawings.forEach( async (id: number) => {
        const i = this.handover.drawings.findIndex( (d: HandOverProjectDrawingRevision) => d.revision.id == id );
        if ( i >= 0 ) {
          await this.server.destroy('project_drawing_handovers', this.handover.drawings[i].id!);
          this.handover.drawings.splice(i, 1);
          this.markDirty();
        }
      });
      */

      this.addedDrawings.forEach( async (id: number) => {
        const i = this.handover.drawings.findIndex( (d: HandOverProjectDrawingRevision) => d.revision.id == id );
        if ( i < 0 ) {
          const res: object_t = await this.server.create('project_drawing_handovers', {
            handover_id: this.handover.id,
            revision_id: id
          });
          this.markDirty();
        }
      });

      this.addedDrawings.clear();
      //this.removedDrawings.clear();
      ! redirect && this.projectHandOverDrawingGridInstance?.extensionService.refreshBackendDataset();
    }

    if ( redirect ) {
      this.nav.goto('/project/handover/' + this.handover.id);
    }
  }

  // ----------------------------------------------------
  // -- validations
  // ----------------------------------------------------

  public markDirty() {
    this.mainForm && this.mainForm.form.markAsDirty();
  }

  public clearDirty() {
    this.mainForm && this.mainForm.form.markAsPristine();
  }

  /**
   * validation logic based on action and document status
   * trig error message or pop error message
   */
  protected async validate(action: 'save'|'submit'|'reject'|'approve' = 'save'): Promise<boolean> {
    this.errors = await this.ui.validateForm(this.mainForm);

    if ( action == 'save' ) {
      return true; // nothing to validate at the moment
    }

    // validations come here!
    return Object.keys(this.errors).length == 0;
  }

  // ----------------------------------------------------
  // -- document flags and control attributes
  // ----------------------------------------------------

  /**
   * check, if project is openable
   */
  public is_submittable(): boolean {
    return  !! this.handover.id && !! this.handover.project && Object.keys(this.errors).length == 0 &&
            this.handover.status == 'draft' &&
            (
              this.api.user_is(this.handover.project, this.api.core_team_name, 'leader') ||
              this.api.user_is(this.handover.project, this.api.core_team_name, 'coordinator') ||
              this.session.hasPermission(['core_admin'])
            );
  }

  public is_rejectable(): boolean {
    return this.is_approvable();
  }

  public is_approvable(): boolean {
    return  !! this.handover.id && !! this.handover.project && Object.keys(this.errors).length == 0 &&
            this.handover.status == 'submitted' &&
            //( this.api.user_is(this.handover.project, this.api.core_team_name, 'leader') || this.session.hasPermission(['core_admin', 'project_gm_mecs']) );
            this.session.hasPermission(['project_gm_mecs']);
  }

  public is_saveable(): boolean {
    return  !! this.handover.project &&
            ['approved', 'closed'].indexOf(this.handover.status) < 0; // not approved or closed
  }

  public is_deletable(): boolean {
    return  !! this.handover.id && !! this.handover.project &&
            ['approved', 'closed'].indexOf(this.handover.status) < 0 && // not approved or closed
            (
              this.api.user_is(this.handover.project, this.api.core_team_name, 'leader') ||
              this.session.hasPermission(['core_admin', 'project_gm_mecs'])
            );
  }

  // ----------------------------------------------------
  // -- Handing Over Project Documents Grid
  // ----------------------------------------------------

  public projectHandOverDocumentColumnDefinitions: Column[] = [];
  public projectHandOverDocumentGridOptions: GridOption|null = null;
  public projectHandOverDocumentGridInstance: AngularGridInstance|null = null;

  protected initProjectHandOverDocumentsGrid() {
    const component = this;

    this.projectHandOverDocumentColumnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        fields: [
          'revision_id', 'soft_copy', 'hard_copies', 'accepted', 'comment',
          'revision.id', 'revision.revision',
          'revision.project_document.id', 'revision.project_document.prefix', 'revision.project_document.status',
        ],
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'rev_id', name: 'Rev. ID',
        field: 'revision_id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'doc_id', name: 'Doc ID',
        field: 'revision.project_document_id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDocumentRevision, grid: SlickGrid): string  => {
          return dataContext.revision?.project_document_id?.toString() || 'n/a';
        }
      },

      {
        id: 'title', name: 'Title',
        field: 'revision.project_document.title',
        fields: [ 'revision.project_document.prefix', 'revision.project_document.title' ],
        type: FieldType.string,
        cssClass: 'text-left', width: 350,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDocumentRevision, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDocumentRevision, grid: SlickGrid): string  => {
          return dataContext.revision.project_document && ( dataContext.revision.project_document.prefix + ' ' + dataContext.revision.project_document.title ) || 'n/a';
        },

        sortable: true,
        filterable: true,
      },

      {
        id: 'revision', name: 'Rev.',
        field: 'revision.revision',
        type: FieldType.number,
        cssClass: 'text-right', minWidth: 60, maxWidth: 60,
        sortable: true,
        filterable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDocumentRevision, grid: SlickGrid): string => {
          return dataContext.revision.revision.toString();
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDocumentRevision, grid: SlickGrid): string  => {
          return dataContext.revision.revision.toString();
        },
      },

      {
        id: 'soft_copy', name: 'Soft Copy',
        field: 'soft_copy',
        type: FieldType.boolean,
        cssClass: 'text-center', minWidth: 70, maxWidth: 70,
        sortable: false,
        filterable: false,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDocumentRevision, grid: SlickGrid): string => {
          return dataContext.accepted ? 'Y' : 'N';
        },
        formatter: Formatters.checkmark,
        editor: {
          model: Editors.checkbox,
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          //console.log('onCellChange', args);
          component.handover.documents[args.row].soft_copy = args.dataContext.soft_copy;
          this.markDirty();
        }
      },

      {
        id: 'hard_copies', name: 'Hard Copies',
        field: 'hard_copies',
        type: FieldType.number,
        cssClass: 'text-right', minWidth: 60, maxWidth: 60,
        sortable: false,
        filterable: false,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDocumentRevision, grid: SlickGrid): string => {
          return dataContext.hard_copies.toString();
        },
        editor: {
          model: Editors.integer,
          minValue: 0,
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          //console.log('onCellChange', args);
          component.handover.documents[args.row].hard_copies = args.dataContext.hard_copies;
          this.markDirty();
        }
      },

      {
        id: 'accepted', name: 'Accepted',
        field: 'accepted',
        type: FieldType.boolean,
        cssClass: 'text-center', minWidth: 70, maxWidth: 70,
        sortable: false,
        filterable: false,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDocumentRevision, grid: SlickGrid): string => {
          return dataContext.accepted ? 'Y' : 'N';
        },
        formatter: Formatters.checkmark,
        editor: {
          model: Editors.checkbox,
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          //console.log('onCellChange', args);
          component.handover.documents[args.row].accepted = args.dataContext.accepted;
          this.markDirty();
        }
      },

      {
        id: 'comment', name: 'Comment',
        field: 'comment',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 250,
        sortable: false,
        filterable: false,
        editor: {
          model: Editors.longText
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          //console.log('onCellChange', args);
          component.handover.documents[args.row].comment = args.dataContext.comment;
          this.markDirty();
        }
      },

      {
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 150,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: [
            {
              name: 'remove',
              title: 'Remove this document from handover.',
              css: 'btn-warning',
              icon: 'pli-trash',
              visible: this.is_saveable(),
              click: async (row: number, col: number, dataContext: HandOverProjectDocumentRevision, config: DataGridButton, grid: SlickGrid) => {
                if ( ! await this.ui.confirm('Delete this document from hand over list?') || ! dataContext.id ) return;
                await this.server.destroy('project_document_handovers', dataContext.id);
                component.projectHandOverDocumentGridInstance && component.projectHandOverDocumentGridInstance.extensionService.refreshBackendDataset();
              }
            }
          ]
        }
      }

    ];

    this.projectHandOverDocumentGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.projectHandOverDocumentColumnDefinitions,
          datasetName: 'project_handover_project_document_revisions',
          persistenceFilteringOptions: [
            { field: 'handover_id', operator: 'EQ', value: this.handover.id?.toString() || null },
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: async (query: string): Promise<GraphqlPaginatedResult|undefined> => {
          try {
            const res: object_t = await this.graphqlServer.sendQuery({query: query});
            const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
            this.handover.documents = re.data['project_handover_project_document_revisions'].nodes.map( (d: object_t) => this.api.createHandOverProjectDocumentRevision(d) );
            return re;
          }
          catch (error: any) {
            this.ui.alert(error.message, undefined, 'Error!');
            console.error('GrqphQL error', error);
          }
          return;
        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      presets: {
        columns: [
          { columnId: 'title' },
          { columnId: 'revision' },
          { columnId: 'soft_copy' },
          { columnId: 'hard_copies' },
          { columnId: 'accepted' },
          { columnId: 'comment' },
          { columnId: 'actions' },
        ]
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Project'
      },

      enableSorting: true,

      //rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#project-handover-doc-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },

      autoEdit: true,
      autoCommitEdit: true,
      editable: true,
      enableCellNavigation: true,
      //asyncEditorLoading: true,
      /* to be deleted - keep for example
      editCommandHandler: (item: HandOverProjectDocumentRevision, column: Column, editCommand: EditCommand): void => {
        console.log('editCommandHandler', item, column, editCommand);
      },
      */

      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
    };
  }

  public onProjectHandOverDocumentGridReady(event: Event) {
    this.projectHandOverDocumentGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onProjectHandOverDocumentGridClick(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const selectedCol: number = (event as CustomEvent).detail.args['cell'];

    if ( selectedCol > 0 ) return;

    const rev = this.projectHandOverDocumentGridInstance?.dataView.getItemByIdx<HandOverProjectDocumentRevision>(selectedRow);
    if ( ! rev || ! rev.id) {
      console.warn(`Project document on row ${selectedRow} could not be found!`);
      return;
    }
    rev.revision.project_document && this.viewDocument(rev.revision.project_document);
  }

  public onProjectHandOverDocumentGridValidationError(event: Event) {
    if ( (event as CustomEvent).detail.args.validationResults.valid ) {
      return;
    }
    this.ui.alert((event as CustomEvent).detail.args.validationResults.msg, undefined, 'Error!');
  }

  //protected removedDocuments: Set<number> = new Set();
  protected addedDocuments: Set<number> = new Set();

  public addDocuments() {
    //this.removedDocuments.clear();
    this.addedDocuments.clear();
    this.selectingDocumentsType = 'document';
    this.projectDocumentGridInstance?.extensionService.refreshBackendDataset();
    this.selectDocumentsModal.show();
    /**  experimental code to check how grid resizing works - to  be removed.
    setTimeout( () => {
      this.projectDocumentGridInstance?.resizerService.resizeGrid(1, {
        width: 200,
        height: 200
      })
    }, 5000);
    */
    /*
    setTimeout( () => {
      this.projectDocumentGridInstance?.dataView.setSelectedIds(this.handover.documents.map( d => d.revision.project_document_id! ), {
        isRowBeingAdded: true,
        shouldTriggerEvent: true,
        applyRowSelectionToGrid: true
      });
    });
    */
  }

  // ----------------------------------------------------
  // -- Handing Over Project Drawings Grid
  // ----------------------------------------------------

  public projectHandOverDrawingColumnDefinitions: Column[] = [];
  public projectHandOverDrawingGridOptions: GridOption|null = null;
  public projectHandOverDrawingGridInstance: AngularGridInstance|null = null;

  protected initProjectHandOverDrawingsGrid() {
    const component = this;

    this.projectHandOverDrawingColumnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        fields: [
          // include all required data here
          'soft_copy', 'hard_copies', 'accepted', 'comment',
          'revision.id',
          'revision.revision', 'revision.project_drawing_id',
          'revision.project_drawing.id', 'revision.project_drawing.drawing_no',
          'revision.project_drawing.title', 'revision.project_drawing.status',
          'revision.project_drawing.status', 'revision.project_drawing.stage'
        ],
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'rev_id', name: 'Rev. ID',
        field: 'revision.id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string  => {
          return dataContext.revision?.id?.toString() || 'n/a';
        }
      },

      {
        id: 'drawing_id', name: 'Dwg. ID',
        field: 'revision.project_drawing_id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string  => {
          return dataContext.revision?.project_drawing_id?.toString() || 'n/a';
        }
      },

      {
        id: 'drawing_no', name: 'Drawing No.',
        field: 'revision.project_drawing.drawing_no',
        type: FieldType.string,
        cssClass: 'text-left', width: 100,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string  => {
          return dataContext.revision.project_drawing?.drawing_no || 'n/a';
        },

        sortable: true,
        filterable: true,
      },

      {
        id: 'title', name: 'Title',
        field: 'revision.project_drawing.title',
        type: FieldType.string,
        cssClass: 'text-left', width: 250,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string => {
          return dataContext.revision.project_drawing?.title || 'n/a';
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string  => {
          return dataContext.revision.project_drawing?.title || 'n/a';
        },

        sortable: true,
        filterable: true,
      },

      {
        id: 'revision', name: 'Rev.',
        field: 'revision.revision',
        type: FieldType.number,
        cssClass: 'text-right', minWidth: 60, maxWidth: 60,
        sortable: true,
        filterable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string => {
          return dataContext.revision.revision.toString();
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string  => {
          return dataContext.revision.revision.toString();
        },
      },

      {
        id: 'soft_copy', name: 'Soft Copy',
        field: 'soft_copy',
        type: FieldType.boolean,
        cssClass: 'text-center', minWidth: 70, maxWidth: 70,
        sortable: false,
        filterable: false,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string => {
          return dataContext.accepted ? 'Y' : 'N';
        },
        formatter: Formatters.checkmark,
        editor: {
          model: Editors.checkbox,
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          //console.log('onCellChange', args);
          component.handover.drawings[args.row].soft_copy = args.dataContext.soft_copy;
          this.markDirty();
        }
      },

      {
        id: 'hard_copies', name: 'Hard Copies',
        field: 'hard_copies',
        type: FieldType.number,
        cssClass: 'text-right', minWidth: 60, maxWidth: 60,
        sortable: false,
        filterable: false,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDrawingRevision, grid: SlickGrid): string => {
          return dataContext.hard_copies.toString();
        },
        editor: {
          model: Editors.integer,
          editorOptions: {
            minValue: 0,
          }
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          //console.log('onCellChange', args);
          component.handover.drawings[args.row].hard_copies = args.dataContext.hard_copies;
          this.markDirty();
        }
      },

      {
        id: 'accepted', name: 'Accepted',
        field: 'accepted',
        type: FieldType.boolean,
        cssClass: 'text-center', minWidth: 70, maxWidth: 70,
        sortable: false,
        filterable: false,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: HandOverProjectDocumentRevision, grid: SlickGrid): string => {
          return dataContext.accepted ? 'Y' : 'N';
        },
        formatter: Formatters.checkmark,
        editor: {
          model: Editors.checkbox,
          params: {
            trueValue: true,
            falseValue: false
          }
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          //console.log('onCellChange', args);
          component.handover.drawings[args.row].accepted = args.dataContext.accepted;
          this.markDirty();
        }
      },

      {
        id: 'comment', name: 'Comment',
        field: 'comment',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 250,
        sortable: false,
        filterable: false,
        editor: {
          model: Editors.longText
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          //console.log('onCellChange', args);
          component.handover.drawings[args.row].comment = args.dataContext.comment;
          this.markDirty();
        }
      },

      {
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 150,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: [
            {
              name: 'remove',
              title: 'Remove this drawing from handover.',
              css: 'btn-warning',
              icon: 'pli-trash',
              visible: this.is_saveable(),
              click: async (row: number, col: number, dataContext: HandOverProjectDrawingRevision, config: DataGridButton, grid: SlickGrid) => {
                if ( ! await this.ui.confirm('Delete this drawing from hand over list?') || ! dataContext.id ) return;
                await this.server.destroy('project_drawing_handovers', dataContext.id);
                component.projectHandOverDrawingGridInstance && component.projectHandOverDrawingGridInstance.extensionService.refreshBackendDataset();
              }
            }
          ]
        }
      }
    ];

    this.projectHandOverDrawingGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.projectHandOverDrawingColumnDefinitions,
          datasetName: 'project_handover_project_drawing_revisions',
          persistenceFilteringOptions: [
            { field: 'handover_id', operator: 'EQ', value: this.handover.id?.toString() || null },
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: async (query: string): Promise<GraphqlPaginatedResult|undefined> => {
          try {
            const res: object_t = await this.graphqlServer.sendQuery({query: query});
            const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
            this.handover.drawings = re.data['project_handover_project_drawing_revisions'].nodes.map( (d: object_t) => this.api.createHandOverProjectDrawingRevision(d) );
            return re;
          }
          catch (error: any) {
            this.ui.alert(error.message, undefined, 'Error!');
            console.error('GrqphQL error', error);
          }
          return;
        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      presets: {
        columns: [
          { columnId: 'drawing_no' },
          { columnId: 'title' },
          { columnId: 'revision' },
          { columnId: 'soft_copy' },
          { columnId: 'hard_copies' },
          { columnId: 'accepted' },
          { columnId: 'comment' },
          { columnId: 'actions' },
        ]
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Project'
      },

      enableSorting: true,

      //rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#project-handover-drawing-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },

      autoEdit: true,
      autoCommitEdit: true,
      editable: true,
      enableCellNavigation: true,
      //asyncEditorLoading: true,

      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
    };
  }

  public onProjectHandoverDrawingGridReady(event: Event) {
    this.projectHandOverDrawingGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public async onProjectHandOverDrawingGridClick(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const selectedCol: number = (event as CustomEvent).detail.args['cell'];

    if ( selectedCol > 1 ) return;

    const rev = this.projectHandOverDrawingGridInstance?.dataView.getItemByIdx<HandOverProjectDrawingRevision>(selectedRow);
    if ( ! rev || ! rev.id) {
      console.warn(`Project drawing on row ${selectedRow} could not be found!`);
      return;
    }
    rev.revision.project_drawing && this.viewDrawing(rev.revision.project_drawing);
  }

  //protected removedDrawings: Set<number> = new Set();
  protected addedDrawings: Set<number> = new Set();

  public addDrawings() {
    //this.removedDrawings.clear();
    this.addedDrawings.clear();
    this.selectingDocumentsType = 'drawing';
    this.projectDrawingGridInstance?.extensionService.refreshBackendDataset();
    this.selectDocumentsModal.show();
    /*
    setTimeout( () => {
      this.projectDrawingGridInstance?.dataView.setSelectedIds(this.handover.drawings.map( d => d.revision.project_drawing_id! ), {
        isRowBeingAdded: true,
        shouldTriggerEvent: true,
        applyRowSelectionToGrid: true
      });
    });
    */
  }

  // ----------------------------------------------------
  // -- Project Documents Grid
  // ----------------------------------------------------

  public projectDocumentColumnDefinitions: Column[] = [];
  public projectDocumentGridOptions: GridOption|null = null;
  public projectDocumentGridInstance: AngularGridInstance|null = null;

  protected initProjectDocumentsGrid() {
    const component = this;
    const prefixes = Object.getOwnPropertyNames(component.api.options['doc_prefix'])
                    .map( (name: string) => {
                      return {
                        //label: component.api.options['doc_prefix'][name],
                        label: name,
                        value: name
                      }
                    });

    this.projectDocumentColumnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'prefix', name: 'Prefix',
        field: 'prefix',
        type: FieldType.string,
        cssClass: 'doc-prefix text-left', minWidth: 150,
        sortable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          return value as string;
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: prefixes
        }
      },

      {
        id: 'title', name: 'Project Document Title',
        field: 'title',
        fields: [ 'prefix', 'title' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 250,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string  => {
          //return dataContext.prefix + ' ' + dataContext.title;
          return dataContext.title;
        },

        sortable: true,
        filterable: true,
      },

      {
        id: 'revision', name: 'Rev.',
        field: 'revisions.revision',
        fields: [ 'revisions.id', 'revisions.revision' ],
        type: FieldType.number,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          return Math.max( ...(dataContext.revisions || []).map( r => r.revision), 0).toString();
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string  => {
          return Math.max( ...(dataContext.revisions || []).map( r => r.revision), 0).toString();
        },

        sortable: true,
        filterable: true,
      },

      {
        id: 'approve_date', name: 'Approve Date',
        field: 'revisions.approve_date',
        type: FieldType.dateIso,
        cssClass: 'text-center', minWidth: 100, maxWidth: 120,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          let s = DatetimeMomentFormatter(row, cell, dataContext.approve_date || null, columnDef, dataContext, grid);
          return s.toString();
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string  => {
          let s = DatetimeMomentFormatter(row, cell, dataContext.approve_date || null, columnDef, dataContext, grid);
          return s.toString();
        },
        params: 'D MMM YYYY', // for dateTimeMoment
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 100, maxWidth: 100,
        sortable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:     { label: 'Draft',     css: 'default' },
            submitted: { label: 'Submitted', css: 'warning' },
            reviewed:  { label: 'Reviewed',  css: 'warning' },
            approved:  { label: 'Approved',  css: 'success' }
          }
          let status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${labels[status]?.css || 'info'}">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: [
            { value: 'draft',     label: 'Draft'     },
            { value: 'submitted', label: 'Submitted' },
            { value: 'reviewed',  label: 'Reviewed'  },
            { value: 'approved',  label: 'Approved'  }
          ]
        }
      }
    ];

    this.projectDocumentGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.projectDocumentColumnDefinitions,
          datasetName: 'project_documents',
          persistenceFilteringOptions: [
            //{ field: 'user', operator: 'EQ', value: this.session.currentUser?.id.toString() || null }
            //{ field: 'accessibility', operator: 'EQ', value: JSON.stringify({ project_id: this.project_id?.toString() || null, user_id: this.session.currentUser?.id.toString() || null }) },
            { field: 'project_id', operator: 'EQ', value: this.handover.project?.id?.toString() || null },
            { field: 'not_in_handover', operator: 'EQ', value: this.handover.id?.toString() || null },
            { field: 'status', operator: 'EQ', value: 'approved' }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: async (query: string): Promise<GraphqlPaginatedResult|undefined> => {
          try {
            const res: object_t = await this.graphqlServer.sendQuery({query: query});
            const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
            this.project_documents = re.data['project_documents'].nodes.map( (d: object_t) => this.api.createProjectDocument(d) );
            return re;
          }
          catch (error: any) {
            this.ui.alert(error.message, undefined, 'Error!');
            console.error('GrqphQL error', error);
          }
          return;
        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      presets: {
        columns: [
          //{ columnId: 'title' },
          //{ columnId: 'approve_date' },
          //{ columnId: 'status' }
        ],
        /*
        rowSelection: {
          //gridRowIndexes: [],
          dataContextIds: this.handover.documents.map( (d: HandOverProjectDocumentRevision) => d.revision_id! )
        }
        */
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Project'
      },

      enableSorting: true,

      //rowHeight: 45,
      enableAutoResize: true,

      // checkbox selector
      enableCheckboxSelector: true,
      checkboxSelector: {
        hideSelectAllCheckbox: false,
        columnIndexPosition: 0,
      },

      autoHeight: true,
      autoResize: {
        container: '#project-document-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
    };
  }

  public onProjectDocumentGridReady(event: Event) {
    this.projectDocumentGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onProjectDocumentGridClick(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const selectedCol: number = (event as CustomEvent).detail.args['cell'];

    if ( selectedCol == 0 ) return;

    const doc = this.projectDocumentGridInstance?.dataView.getItemByIdx<ProjectDocument>(selectedRow);
    if ( ! doc || ! doc.id) {
      console.warn(`Project document on row ${selectedRow} could not be found!`);
      return;
    }
    this.viewDocument(doc);
  }

  public onProjectDocumentGridSelectedRowsChanged(event: Event) {
    ((event as CustomEvent).detail.args['changedUnselectedRows'] || []).forEach( (row: number) => {
      const doc = this.projectDocumentGridInstance?.dataView.getItemByIdx<ProjectDocument>(row);
      if ( doc && doc.revisions.length > 0 && doc.revisions[0].id ) { // latest revision alwasy on top
        this.addedDocuments.delete(doc.revisions[0].id!);
        //this.removedDocuments.add(doc.revisions[0].id);
      }
    });

    ((event as CustomEvent).detail.args['changedSelectedRows'] || []).forEach( (row: number) => {
      const doc = this.projectDocumentGridInstance?.dataView.getItemByIdx<ProjectDocument>(row);
      if ( doc && doc.revisions.length > 0 && doc.revisions[0].id ) { // latest revision alwasy on top
        //this.removedDocuments.delete(doc.revisions[0].id);
        this.addedDocuments.add(doc.revisions[0].id);
      }
    });
  }

  // ----------------------------------------------------
  // -- Project Drawings Grid
  // ----------------------------------------------------

  public projectDrawingColumnDefinitions: Column[] = [];
  public projectDrawingGridOptions: GridOption|null = null;
  public projectDrawingGridInstance: AngularGridInstance|null = null;

  protected initProjectDrawingsGrid() {
    const component = this;

    this.projectDrawingColumnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        fields: [
          // include all required data here
          'last_uploader_id', 'last_approver_id', 'last_reviewer_id',
          'revisions.id',
          'revisions.attachments.id', 'revisions.attachments.file_name', 'revisions.attachments.file_size', 'revisions.attachments.download_url',
          'revisions.revision', 'revisions.uploader_id',
          'revisions.reviewer_id', 'revisions.review_date',
          'revisions.approver_id', 'revisions.approve_date'
        ],
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'drawing_no', name: 'Drawing No.',
        field: 'drawing_no',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 150, maxWidth: 220,
        sortable: true,
        filterable: true,
      },

      {
        id: 'title', name: 'Title',
        field: 'title',
        type: FieldType.string,
        cssClass: 'text-left',
        sortable: false,
        filterable: true,
      },

      {
        id: 'stage', name: 'Stage',
        field: 'stage',
        type: FieldType.string,
        cssClass: 'text-center', minWidth: 150, maxWidth: 150,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string  => {
          return this.api.options['drawing_stage'][value] || value;
        },
        sortable: true,
        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: this.api.getOptionsAsGridFilter('drawing_stage').filter( (o: {value: string, label: string}) => ['as_built_drawing', 'shop_drawing'].includes(o.value) ),
        }
      },
      {
        id: 'system', name: 'System',
        field: 'system',
        type: FieldType.string,
        cssClass: 'text-center', minWidth: 150, maxWidth: 150,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string  => {
          return this.api.options['drawing_system'][value] || value;
        },
        sortable: true,
        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: this.api.getOptionsAsGridFilter('drawing_system'),
        }
      },

      {
        id: 'revision', name: 'Rev.',
        field: 'last_revision',
        type: FieldType.integer,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: false,
        /*
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          return Math.max( ...(dataContext.revisions || []).map( r => r.revision), 0).toString();
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string  => {
          return Math.max( ...(dataContext.revisions || []).map( r => r.revision), 0).toString();
        },
        */
      },

      {
        id: 'approve_date', name: 'Approve Date',
        field: 'last_approve_date',
        type: FieldType.dateIso,
        cssClass: 'text-center', minWidth: 100, maxWidth: 120,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string => {
          let s = DatetimeMomentFormatter(row, cell, dataContext.approve_date || null, columnDef, dataContext, grid);
          return s.toString();
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string  => {
          let s = DatetimeMomentFormatter(row, cell, dataContext.approve_date || null, columnDef, dataContext, grid);
          return s.toString();
        },
        params: 'D MMM YYYY', // for dateTimeMoment
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 100, maxWidth: 100,
        sortable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:     { label: 'Draft',     css: 'default' },
            submitted: { label: 'Submitted', css: 'warning' },
            reviewed:  { label: 'Reviewed',  css: 'warning' },
            approved:  { label: 'Approved',  css: 'success' }
          }
          let status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${ labels[status]?.css || 'info' }">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: this.api.getOptionsAsGridFilter('doc_status'),
          /*
          collection: [
            { value: 'draft',     label: 'Draft'     },
            { value: 'reviewed',  label: 'Reviewed'  },
            { value: 'approved',  label: 'Approved'  }
          ]
          */
        }
      },

    ];

    this.projectDrawingGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.projectDocumentColumnDefinitions,
          datasetName: 'project_drawings',
          persistenceFilteringOptions: [
            //{ field: 'project_id', operator: 'EQ', value: this.project.id?.toString() || null },
            //{ field: 'user', operator: 'EQ', value: this.session.currentUser?.id.toString() || null }
            { field: 'accessibility', operator: 'EQ', value: JSON.stringify({ project_id: this.project_id!.toString() || null, user_id: this.session.currentUser?.id.toString() || null }) },
            { field: 'not_in_handover', operator: 'EQ', value: this.handover.id?.toString() || null },
            { field: 'status', operator: 'EQ', value: 'approved' },
            { field: 'stage', operator: 'IN', value: JSON.stringify(['as_built_drawing', 'shop_drawing']) }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: async (query: string): Promise<GraphqlPaginatedResult|undefined> => {
          try {
            const res: object_t = await this.graphqlServer.sendQuery({query: query});
            const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
            //this.project_drawings = re.data['project_drawings'].nodes.map( (d: object_t) => this.api.createProjectDrawing(d) );
            re.data['project_drawings'].nodes = re.data['project_drawings'].nodes.map( (d: object_t) => d = this.api.createProjectDrawing(d) );
            this.project_drawings = re.data['project_drawings'].nodes;
            return re;
          }
          catch ( error: unknown) {
            this.ui.alert( (error as Error).message, undefined, 'Error!');
            console.error('GrqphQL error', error);
          };
          return;
        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      presets: {
        columns: [
          /*
          { columnId: 'drawing_no' },
          { columnId: 'title' },
          { columnId: 'stage' },
          { columnId: 'revision' },
          { columnId: 'approve_date' },
          { columnId: 'status' },
          { columnId: 'actions' }
           */
        ],
        /*
        rowSelection: {
          //gridRowIndexes: [],
          dataContextIds: this.handover.drawings.map( (d: HandOverProjectDrawingRevision) => d.revision.id! )
        }
        */
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'ProjectDrawings'
      },

      enableSorting: true,

      // checkbox selector
      enableCheckboxSelector: true,
      checkboxSelector: {
        hideSelectAllCheckbox: false,
        columnIndexPosition: 0,
      },

      //rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#project-drawing-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },

      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
    };
  }

  public onProjectDrawingGridReady(event: Event) {
    this.projectDrawingGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public async onProjectDrawingGridClick(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const selectedCol: number = (event as CustomEvent).detail.args['cell'];

    if ( selectedCol == 0 ) return;

    const drawing = this.projectDrawingGridInstance?.dataView.getItemByIdx<ProjectDrawing>(selectedRow);
    if ( ! drawing || ! drawing.id ) {
      console.warn(`Project drawing on row ${selectedRow} could not be found!`);
      return;
    }
    this.viewDrawing(drawing);
  }

  public onProjectDrawingGridSelectedRowsChanged(event: Event) {
    ((event as CustomEvent).detail.args['changedUnselectedRows'] || []).forEach( (row: number) => {
      const doc = this.projectDrawingGridInstance?.dataView.getItemByIdx<ProjectDrawing>(row);
      if ( doc && doc.revisions.length > 0 && doc.revisions[0].id ) { // latest revision alwasy on top
        this.addedDrawings.delete(doc.revisions[0].id);
        //this.removedDrawings.add(doc.revisions[0].id);
      }
    });

    ((event as CustomEvent).detail.args['changedSelectedRows'] || []).forEach( (row: number) => {
      const doc = this.projectDrawingGridInstance?.dataView.getItemByIdx<ProjectDrawing>(row);
      if ( doc && doc.revisions.length > 0 && doc.revisions[0].id ) { // latest revision alwasy on top
        //this.removedDrawings.delete(doc.revisions[0].id);
        this.addedDrawings.add(doc.revisions[0].id);
      }
    });
  }

  // ----------------------------------------------------
  // -- Workflow action
  // ----------------------------------------------------
  public async action(action: 'submit'|'approve'|'reject') {

    if ( ! this.handover.id ) {
      this.ui.alert(`Cannot ${action} this project document hand over. Please save it first.`);
      return;
    }

    if ( ! await this.validate(action) ) {
      this.ui.alert(`Cannot ${action} this project document hand over. Information is not completed. Please check.`);
      return;
    }
    if ( ! await this.ui.confirm(`Please confirm to ${action} the project document hand over?`) ) {
      return;
    }

    // save project first, if edited or dirty
    if ( this.mainForm && this.mainForm.dirty ) {
      await this.save();
    }

    try {
      await this.server.rejectOnError()
      .request('project_handovers/{id}/action/{action}', {
        id: this.handover.id,
        action: action
      });
    }
    catch ( error ) {
      console.error(`Cannot perform action ${action} - `, error);
    }

    this.refresh();
  }
}
