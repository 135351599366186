<full-page [page-title]="'Home'">
  <div page-content>
    <div class="panel p-2 pt-4">
      <div class="row">
        <div *ngIf="false" class="col-6 col-xs-12">
          <iframe id="campaign"
            title="campaign"
            class="panel"
            width="100%"
            [attr.height]="iframe_height"
            src="https://engportal.siampiwat.in.th/forums"
            #campaignFrame
          >
          </iframe>
        </div>

        <!---------------------------------->
        <!-- CMMS Redirection Chart -->
        <div class="col-6 col-xs-12">
          <panel>
            <div panel-control>
              <button class="btn btn-icon" (click)="fileUtils.saveXLSX('maximo_redirection', charts['redirect_hourly'].config.data)" [tooltip]="'Export to Excel'">
                <i class="pli-file-excel"></i>
              </button>
            </div>
            <div panel-heading>
              CMMS Redirections in 24 Hours by Hour
            </div>
            <div panel-body>
              <div id="cmms-redirect-by-hour" [chart]="charts['redirect_hourly'].type" [chart-config]="charts['redirect_hourly'].config" [chart-data]="charts['redirect_hourly'].config.data" style="height: 210px"></div>
            </div>
          </panel>
        </div>
        <!-- end of CMMS Redirection Chart -->
        <!---------------------------------->

        <!---------------------------------->
        <!-- CMMS Redirection Chart -->
        <div class="col-6 col-xs-12">
          <panel>
            <div panel-control>
              <button class="btn btn-icon" (click)="fileUtils.saveXLSX('team_redirect', charts['team_redirect'].config.data)" [tooltip]="'Export to Excel'">
                <i class="pli-file-excel"></i>
              </button></div>
            <div panel-heading>CMMS Redirections in 24 Hours by Team</div>
            <div panel-body>
              <div id="cmms-redirect-by-team" [chart]="charts['team_redirect'].type" [chart-config]="charts['team_redirect'].config" [chart-data]="charts['team_redirect'].config.data" style="height: 210px"></div>
            </div>
          </panel>
        </div>
        <!-- end of CMMS Redirection Chart -->
        <!---------------------------------->

        <!-- OPL report -->
        <div class="col-6 col-xs-12">
          <panel>
            <div panel-control>
              <button class="btn btn-icon" [tooltip]="'Export to Excel'" (click)="fileUtils.saveXLSX('opl-by-time', charts['opl_by_time'].config.data)">
                <i class="pli-file-excel"></i>
              </button>
            </div>
            <div panel-heading>OPL by Time</div>
            <div panel-body>
              <div id="opl-by-time" [chart]="charts['opl_by_time'].type" [chart-config]="charts['opl_by_time'].config" [chart-data]="charts['opl_by_time'].config.data" style="height: 210px"></div>
              <!--
              <div style="position: relative;">
                <maximizable class="full-height">
                  <d3chart #opl_by_time [type]="charts.opl_by_time.type" [data]="charts.opl_by_time.data" [options]="charts.opl_by_time.config" class="fill-height"></d3chart>
                  <table *ngIf="charts.opl_by_time.data.length > 0" class="table table-condensed table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th *ngFor="let m of charts.opl_by_time.data[0].values">{{ m.x }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let d of charts.opl_by_time.data">
                        <td>{{ d['key'] }}</td>
                        <td *ngFor="let v of d['values']">{{ v['y'] }}</td>
                      </tr>
                    </tbody>
                  </table>
                </maximizable>
              </div>
              -->
            </div>
          </panel>
        </div>
        <div class="col-6 col-xs-12">
          <panel>
            <div panel-control>
              <button class="btn btn-icon" [tooltip]="'Export to Excel'" (click)="fileUtils.saveXLSX('opl-by-team', charts['opl_by_team'].config.data)">
                <i class="pli-file-excel"></i>
              </button>
            </div>
            <div panel-heading>OPL by Team</div>
            <div panel-body>
              <div id="opl-by-team" [chart]="charts['opl_by_team'].type" [chart-config]="charts['opl_by_team'].config" [chart-data]="charts['opl_by_team'].config.data" (chart-click)="chartDrillDown('opl_by_team', $event)" style="height: 210px"></div>
              <!--
              <div style="position: relative;">
                <maximizable class="full-height">
                  <d3chart #opl_by_time [type]="charts.opl_by_team.type" [data]="charts.opl_by_team.data" [options]="charts.opl_by_team.config" class="fill-height"></d3chart>
                  <table *ngIf="charts.opl_by_team.data.length > 0" class="table table-condensed table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th *ngFor="let m of charts.opl_by_team.data[0].values">{{ m.x }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let d of charts.opl_by_team.data">
                        <td>{{ d['key'] }}</td>
                        <td *ngFor="let v of d['values']">{{ v['y'] }}</td>
                      </tr>
                    </tbody>
                  </table>
                </maximizable>
              </div>
              -->
            </div>
          </panel>
        </div>
      </div>
    </div>
  </div>
</full-page>