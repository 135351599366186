import * as XLSX from 'xlsx-js-style';

import {
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NavigationService,
  object_t,
  ServerService,
  SessionService
} from '@pinacono/common';

import {
  ModalComponent,
  UIService
} from '@pinacono/ui';

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  ExcelExportService
} from '@slickgrid-universal/excel-export';

import {
  GraphqlFilteringOption,
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  AngularUtilService,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  ButtonsFormatter,
  ComponentFormatter,
  DataGridButton,
  DatetimeMomentFormatter,
  GraphQLServerService,
  GridUserComponent,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { AppUser } from 'src/app/types';
import { BasePageComponent } from 'src/app/classes/base-page.component';

import {
  ReleaseBundle,
  ReleaseBundleRecipient
} from '../../types';

import {
  ProjectLibService
} from '../../projects.service';

@Component({
  selector: 'page-projlib-release-browse',
  templateUrl: 'releases.html',
  styleUrls: ['releases.scss', 'drawings-list.scss'],
  providers: [ AngularUtilService ]
})
export class ProjectLibReleaseBrowsePage extends BasePageComponent implements OnInit {
  @ViewChild('releaseInfoModal') releaseInfoModal!: ModalComponent;
  @ViewChild('drawingsListDialog') drawingsListDialog!: ModalComponent;
  @ViewChild('drawingsListContainer') drawingsListContainer!: ElementRef;

  // data
  public data: ReleaseBundle[] = [];
  public selectedData: ReleaseBundle|null = null;

  /**
   * template variables
   */
  public readonly title: string = 'Project Document Releases';

  /**
   * graphQL configuration
   */

  protected readonly dataSetName: string = 'project_release_bundles';

  /** @TODO - add required filter */
  protected filters: GraphqlFilteringOption[] = [
  ];

  protected trashed: 'WITH'|'WITHOUT'|'ONLY' = 'WITH';

  // grid interface
  protected gridComponent: AngularGridInstance|null = null;
  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  // -- lifecycle

  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    protected ngUtilService: AngularUtilService,
    protected nav: NavigationService,
    protected ui: UIService,
    protected api: ProjectLibService,
    protected server: ServerService,
    protected graphqlServer: GraphQLServerService,
    protected session: SessionService
  ) {
    super(router, activatedRoute);
  }

  public override ngOnInit(): void {
    this.initGrid();
    super.ngOnInit();
  }

  // -- overloading

  protected  silent: boolean = true;
  protected override loadData(): Promise<void> {
    if ( !! this.gridComponent ) {
      this.silent = false;
      this.gridComponent.extensionService.refreshBackendDataset();
    }
    return Promise.resolve();
  }

  // -- grid interfaces

  protected createDataModel(data: object_t): ReleaseBundle {
    return this.api.createReleaseBundle(data);
  }

  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      const server = this.silent ? this.graphqlServer.silent() : this.graphqlServer;
      this.silent = true;

      server.sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.data = re.data[this.dataSetName].nodes.map( d => this.createDataModel(d) );
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.error('GrqphQL error', error);
        }
      );
    });
  }

  public onGridReady(event: Event) {
    //grid: AngularGridInstance
    this.gridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  protected initGrid() {
    /** @TODO - update colum definitions */
    // -- Grid columns definitions
    this.columnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        type: FieldType.string,
        cssClass: 'text-right', maxWidth: 75,
        sortable: true,
        filterable: true,
      },

      {
        id: 'project', name: 'Project',
        field: 'project.title',
        fields: [ 'project', 'project.title', 'project.title_prefix', 'project.title_suffix' ],
        type: FieldType.object,
        cssClass: 'text-left', minWidth: 200,
        sortable: false,
        filterable: true,
        exportCustomFormatter: (row: number, cell: number, value: string, columnDef: Column, dataContext: ReleaseBundle, grid: SlickGrid): string => {
          if ( ! dataContext.project ) return 'n/a';
          return ( dataContext.project.title_prefix && ( dataContext.project.title_prefix + ' ' ) ) +
                 dataContext.project.title +
                 ( dataContext.project.title_suffix && ( ' ' + dataContext.project.title_suffix ) );
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: string, columnDef: Column, dataContext: ReleaseBundle, grid: SlickGrid): string  => {
          if ( ! dataContext.project ) return 'n/a';
          return ( dataContext.project.title_prefix && ( dataContext.project.title_prefix + ' ' ) ) +
                 dataContext.project.title +
                 ( dataContext.project.title_suffix && ( ' ' + dataContext.project.title_suffix ) );
        },
      },

      {
        id: 'subject', name: 'Mail Subject',
        field: 'subject',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 200,
        sortable: false,
        filterable: true,
      },

      {
        id: 'author', name: 'Sender',
        field: 'author',
        fields: [ 'author.id', 'author.avatar', 'author.avatar.thumb_url', 'author.email', 'author.fullname', 'author.profiles.staff_id' ],
        type: FieldType.object,
        cssClass: 'text-left', width: 150,
        sortable: true,
        filterable: true,
        formatter: ComponentFormatter,
        exportCustomFormatter: (row: number, cell: number, value: AppUser, columnDef: Column, dataContext: ReleaseBundle, grid: SlickGrid): string => {
          return `${value.fullname} (${value.email})`;
        },
        params: {
          //component: GridUserComponent,
          factory: () => this.ngUtilService.createAngularComponent(GridUserComponent),
          config: {
            attribute: 'author'
          }
        }
      },

      {
        id: 'create_date', name: 'Create Date',
        field: 'created_at',
        fields: [ 'created_at', 'updated_at', 'deleted_at' ],
        type: FieldType.dateIso,
        cssClass: 'text-center', width: 75,
        exportCustomFormatter: (row: number, cell: number, value: string, columnDef: Column, dataContext: ReleaseBundle, grid: SlickGrid): string => {
          let s = DatetimeMomentFormatter(row, cell, value || null, columnDef, value, grid);
          return s.toString();
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: string, columnDef: Column, dataContext: ReleaseBundle, grid: SlickGrid): string  => {
          let s = DatetimeMomentFormatter(row, cell, value || null, columnDef, value, grid);
          return s.toString();
        },
        params: 'D MMM YYYY', // for dateTimeMoment
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundDate
        }
      }
    ];

    // -- Grid action buttons definitions
    const buttons: DataGridButton[] = [
      {
        name: 'dwg-list',
        title: 'Drawings List',
        css: 'btn-mint',
        icon: 'pli-file',
        visible: function(row: number, cell: number, dataContext: ReleaseBundle, columnDef: Column, grid: SlickGrid): boolean {
          return ! dataContext.deleted_at;
        },
        click: async (row: number, col: number, dataContext: ReleaseBundle, config: DataGridButton, grid: SlickGrid) => {
          dataContext.id && this.generateDrawingsList(dataContext.id);
        }
      },

      {
        name: 'delete',
        title: 'Delete this release',
        css: 'btn-default',
        icon: 'fa-solid fa-trash-can',
        visible: function(row: number, cell: number, dataContext: ReleaseBundle, columnDef: Column, grid: SlickGrid): boolean {
          return ! dataContext.deleted_at;
        },
        click: async (row: number, col: number, dataContext: ReleaseBundle, config: DataGridButton, grid: SlickGrid) => {
          if ( await this.ui.confirm('Mark this release as deleted (soft delete)?') ) {
            await this.server.destroy('projects/release_bundles', dataContext.id!);
            this.refresh();
          }
        }
      },

      {
        name: 'restore',
        title: 'Restore this release',
        css: 'btn-default',
        icon: 'pli-recycling-2',
        //icon: 'fa-solid fa-recycle',
        visible:  function(row: number, cell: number, dataContext: ReleaseBundle, columnDef: Column, grid: SlickGrid): boolean {
          return !! dataContext.deleted_at;
        },
        click: async (row: number, col: number, dataContext: ReleaseBundle, config: DataGridButton, grid: SlickGrid) => {
          if ( await this.ui.confirm('Retstore this release?') ) {
            await this.server.restore('projects/release_bundles', dataContext.id!);
            this.refresh();
          }
        }
      },

      {
        name: 'trash',
        title: '(Hard) Delete this document',
        css: 'btn-default',
        icon: 'fa-solid fa-bomb',
        visible:  function(row: number, cell: number, dataContext: ReleaseBundle, columnDef: Column, grid: SlickGrid): boolean {
          return !! dataContext.deleted_at;
        },
        click: async (row: number, col: number, dataContext: ReleaseBundle, config: DataGridButton, grid: SlickGrid) => {
          if ( await this.ui.confirm('Permanently delete (hard delete) this release?') ) {
            await this.server.purge('projects/release_bundles', dataContext.id!)
            this.refresh();
          }
        },
      },
    ]

    if ( buttons.length > 0 ) {
      this.columnDefinitions.push({
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', width: 75,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: buttons
        }
      });
    }

    // -- Grid options
    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: this.dataSetName,
          persistenceFilteringOptions: this.filters,
          paginationOptions: {
            first: 20
          },
          extraQueryArguments: [
            { field: 'trashed', value: this.trashed }
          ]
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      enableExcelExport: true,
      registerExternalResources: [
        new ExcelExportService()
      ],
      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Documents'
      },

      enableSorting: true,

      rowHeight: 60,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#main-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,

      presets: {
        /** @TODO - initial grid preset option here */
      }
    };
  }

  public async onSelectRow(event: Event) {
    const r = (event as CustomEvent).detail.args['row'];
    const d = this.data[r];
    const w = [ 'recipients', 'project', 'files' ];
    const res = await this.server.show('projects/release_bundles', d.id!, { with: w.join(',') });
    this.selectedData = this.api.createReleaseBundle(res);
    this.releaseInfoModal.show();
  }

  // ----------------------------------------------------
  // Drawings List
  // ----------------------------------------------------

  public selectedRelease: number = 0;
  public async generateDrawingsList(release_bundle_id: number) {
    this.selectedRelease = release_bundle_id;
    this.drawingsListDialog.show();
  }

  public async saveDrawingsListAsExcel() {
    const widths = [
      { wpx: 150},
      { wpx: 400},
      { wpx: 60},
    ];
    for ( let i = 0; i < 30; i++ ) {
      widths.push({ wpx: 50 });
    }

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    this.drawingsListContainer.nativeElement.querySelectorAll('table').forEach( (t: HTMLTableElement) => {
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(t);
      ws['!cols'] = widths;
      XLSX.utils.book_append_sheet(wb, ws);
    });
    XLSX.writeFile(wb, 'DrawingsList.xlsx');
  }
}
