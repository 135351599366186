import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
/* remove @fullcalendar
import { CalendarOptions, DateSelectArg, FullCalendarComponent } from '@fullcalendar/angular';
*/

import { OPL } from 'src/app/modules/opl/types';

import {
  MorrisChart,
  UIService
} from '@pinacono/ui';

import { AppUser } from 'src/app/types';

import {
  FileUtilsService,
  NavigationService,
  PaginatedResults,
  ServerService,
  SessionService
} from '@pinacono/common';

/**
 * chart configuration
 */
interface ChartConfig {
  type: MorrisChart.ChartType;
  config: MorrisChart.ILineOptions | MorrisChart.IAreaOptions | MorrisChart.IBarOptions | MorrisChart.IDonutOptions;
};

/** Forum topic interface */
interface ForumTopic {
  /** Topic id */
  id?: string,
  /** Topic title */
  title?: string,
  /** Author information */
  author?: AppUser
  /** Last updated date */
  updated_at?: Date
};

enum CalendarEntryType {
  holiday, event, workorder
};

/** Calendar interface */
interface CalendarEntry {
  /** Repeated event id */
  id?: string;
  /** Entry title */
  title: string;
  /** Start date/time in ISO-8601 format */
  start: string|null;
  /** End date/time in ISO-8601 format */
  end: string|null;
  /** all-day or not */
  allDay?: boolean;
  /** Nifty's color class name */
  className?: string;
};

/** Maximo Calendar */
interface MaximoCalendar {
  [dept: string]: {
    [date: string]: {
      /** start date */
      start: string;
      /** work description */
      title: string;
      /** group name */
      group: string
    }[];
  };
};

/**
 * The Home Page Component
 */
@Component({
  selector: 'app-home-page',
  templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('campaignFrame') campaignFrame!: ElementRef<HTMLIFrameElement>;

  // -- chart configurations
  public charts : { [name: string]: ChartConfig } = {

    // maximo redirection
    redirect_hourly: {
      type: 'Bar',
      config: {
        xkey: 'hour',
        ykeys: ['count'],
        labels: ['Users'],
        xLabelAngle: 80,
        data: []
      },
    },
    team_redirect: {
      type: 'Bar',
      config: {
        xkey: 'name',
        ykeys: ['count'],
        labels: ['Users'],
        xLabelAngle: 80,
        data: []
      },
    },

    // OPL
    opl_by_time: {
      // -- morris
      type: 'Bar',
      config: {
        xkey: 'month',
        ykeys: ['count'],
        labels: ['OPL'],
        xLabelAngle: 80,
        data: []
      },
    },
    opl_by_team: {
      // -- morris
      type: 'Bar',
      config: {
        xkey: 'name',
        ykeys: ['count'],
        labels: ['OPL'],
        xLabelAngle: 80,
        data: []
      },
    }
  };

  /* remove @fullcalendar
  // calendar - see https://fullcalendar.io/docs#toc
  @ViewChild('calendar') calendar!: FullCalendarComponent;
  public calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    select: this.loadMaximoCalendar.bind(this)
  };
  public calendarEvents: CalendarEntry[] = [];
  */

  // opls
  public opls: OPL[] = [];
  public opls_pagination = {
    pageno: 0,
    perpage: 10,
    total: 0,
    sorting: null
  }
  public opls_total = 0;
  public opls_read = 0;
  public opls_written = 0;

  // forum topics
  public forums: ForumTopic[] = [];

  constructor(
    public nav: NavigationService,
    public fileUtils: FileUtilsService,
    protected activatedRoute: ActivatedRoute,
    protected session: SessionService,
    protected ui: UIService,
    protected server: ServerService,
    protected http: HttpClient
  ) {
  }

  /**
   * load page's data - see nifty-page.component.ts
   */
  public async ngOnInit() {
    // -- load charts data
    let res: any;

    res = await this.server.silent().request('/dashboard/hourly_redirects');
    this.charts['redirect_hourly'].config.data = res;

    res = await this.server.silent().request('/dashboard/team_redirects')
    this.charts['team_redirect'].config.data = res;

    res = await this.server.silent().request('/dashboard/opl_by_time')
    this.charts['opl_by_time'].config.data = res;

    res = await this.server.silent().request('/dashboard/opl_by_team')
    this.charts['opl_by_team'].config.data = res;
  }

  public iframe_height: number = 100;
  protected resizeObserver: ResizeObserver|null = null;
  public async ngAfterViewInit() {
    const aspect_ratio = 16/9;
    this.resizeObserver = new ResizeObserver( (entries: ResizeObserverEntry[]) => {
      entries.forEach( (entry:ResizeObserverEntry) => {
        this.iframe_height = Math.floor(entry.contentRect.width / aspect_ratio);
      });
    });
    this.campaignFrame && this.resizeObserver.observe(this.campaignFrame.nativeElement);
  }

  /**
   * handle click on chart - make drilldown
   */
  public chartDrillDown(name: string, data: MorrisChart.ChartClickEvent) {
    //console.log(`Click on ${name}`, data);
  }

  /**
   * Load OPL with paging
   */
  public async loadOPLPage( pageno: number|null = null ) {
    //return; // temp for production
    this.opls_pagination.pageno = pageno || this.opls_pagination.pageno;

    const opls: PaginatedResults<OPL> = await this.server.silent().index('opls', {
      name: 'unread'
    }, {
      pageno: this.opls_pagination.pageno,
      perpage: this.opls_pagination.perpage
    }, {
      format: 'compact'
    })
    this.opls_pagination.pageno  = opls.pageno;
    this.opls_pagination.perpage = opls.perpage;
    this.opls_pagination.total   = opls.total;
    this.opls = opls.data;

    let res: number;

    res = await this.server.silent().index('opls', { name: 'mine', count: true})
    this.opls_total = res;

    res = await this.server.silent().index('opls', { name: 'read', count: true})
    this.opls_read = res;

    res = await this.server.silent().index('opls', { name: 'approved', count: true })
    this.opls_written = res;
  }

  /**
   * utility to get css class for a calendar entry
   */
  protected getCalendarEntryClass(type: CalendarEntryType, workload: number = 0): string  {
    if ( type == CalendarEntryType.holiday ) {
      return 'pink';
    }

    if ( type == CalendarEntryType.workorder ) {
      if ( workload >= 50 ) {
        return 'danger';
      }
      return 'mint'
    }

    if ( type == CalendarEntryType.event ) {
      return 'info'
    }

    return 'gray';
  }
}
