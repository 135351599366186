import {
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NgForm
} from '@angular/forms';

import {
  Attachment,
  config,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  UserService,
  TaxonomyService,
  UtilsService
} from '@pinacono/common';

import {
  LookupEvent,
  LookupItem,
  ModalComponent,
  UIService,
} from '@pinacono/ui';

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  ButtonsFormatter,
  DataGridButton,
  DatetimeMomentFormatter,
  GraphQLServerService,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { IIP } from '@pinacono/iip-viewer';
import { BasePageComponent } from 'src/app/classes/base-page.component';

import { DocLibService } from 'src/app/modules/documents/doclib.service';

import { ProjectLibService } from '../projects.service';
import {
  Drawing,
  IIPDrawingPrototypeShape,
  MasterDrawing,

  ExternalContact,
  ExternalProjectRole,
  InternalProjectRole,
  Project,
  ProjectDistributionList,
  ProjectDrawing,
  ProjectDocument,
  ReleaseBundle,
  ReleaseBundleRecipient,
  InternalRoleConfig,
  ReleaseBundleTemplate,
  ProjectDrawingRevision,
} from '../types';

// -- component

@Component({
  selector: 'page-projlib-view',
  templateUrl: 'view.html',
  styleUrls: [ 'view.scss' ]
})
export class ProjectLibViewPage extends BasePageComponent {

  public images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  // view child
  @ViewChild('mainForm') mainForm!: NgForm;

  @ViewChild('distributionListForm') distributionListForm!: NgForm;
  @ViewChild('distributionListDialog') distributionListDialog!: ModalComponent;

  @ViewChild('documentViewDialog') documentViewDialog!: ModalComponent;

  @ViewChild('drawingViewDialog')  drawingViewDialog!: ModalComponent;
  @ViewChild('editPolygonDialog')  editPolygonDialog!: ModalComponent;

  @ViewChild('bundleViewDialog')   bundleViewDialog!: ModalComponent;
  @ViewChild('selectDistributionListModal') selectDistributionListModal!: ModalComponent;

  @ViewChild('bundleTemplateViewDialog') bundleTemplateViewDialog!: ModalComponent;

  // keyvalue pipe comparator
  public originalOrder(): number { return 0; };

  // data
  public current_user_roles: string[] = [];
  public project: Project;

  public teams: InternalRoleConfig[] = [];

  public selectedDocument: ProjectDocument|null = null;
  public selectedDrawing: ProjectDrawing|null = null;

  public iip_base_path: string = '';
  public mastersList: Drawing[] = [];
  public polygons: IIP.Polygon[] = [];
  public polygonPrototype = IIPDrawingPrototypeShape;

  // ----------------------------------------------------
  // -- life cycle
  // ----------------------------------------------------

  // constructor
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    //public validator: PinaconoValidatorsService,
    public api: ProjectLibService,
    public docapi: DocLibService,
    public utils: UtilsService,
    protected server: ServerService,
    protected taxonomy: TaxonomyService,
    protected user: UserService,
    protected graphqlServer: GraphQLServerService,
  ) {
    super(router, activatedRoute);
    this.project  = this.api.createProject();
    this.releaseBundle = this.api.createReleaseBundle();

    // organization
    this.teams = api.options['roles'].internal;

    // master searching
    this.iip_base_path = config('client.drawing.iip_base_path')
  }

  //public doc_domain: string = '';
  protected project_documents: ProjectDocument[] = [];
  protected project_drawings: ProjectDrawing[] = [];

  protected with = [
    'documentable',
    'uploader',
    'internal_project_roles', 'internal_project_roles.user',
    'external_project_roles', 'external_project_roles.contact',
    'vendor_project_roles',   'vendor_project_roles.contact',
    //'documents', 'drawings',
    'comments'
  ];
  protected appends = [
    'masters'
  ];
  //protected silent: boolean = true;
  protected override loadData(): Promise<any> {
    this.project = this.api.createProject();

    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if ( id === null ) {
      this.nav.goto('/projlib/browse/default');
      return Promise.resolve();
    }

    let nid: number = parseInt(id);
    if ( isNaN(nid) ) {
      this.ui.alert(`Project id "${id}" could not be found!`);
      this.nav.back();
      return Promise.resolve();
    }

    // reset grid
    this.projectDocumentGridOptions = null;
    this.projectDrawingGridOptions  = null;

    return this.server
    .rejectOnError(true)
    .show('projects', nid, {
      with: this.with.join(','),
      appends: this.appends.join(',')
      //withtrashed: true
    })
    .then( (res: object_t) => {
      this.project = this.api.createProject(res);
      /*
      if ( this.project.documentable.domain ) {
        this.doc_domain = this.docapi.getDepartmentCode(this.project.documentable.domain.path!);
      }
      */
      this.current_user_roles = this.project.internal_project_roles
                                .filter( r => r.user_id == this.session.currentUser!.id )
                                .map( r => r.project_role );

      this.teams = this.api.getAllProjectTeams(this.project);

      this.resetBundle();
      this.initDistributionListGrid();
      this.initProjectDocumentsGrid();
      this.initProjectDrawingsGrid();
      this.initBundleTempaltesGrid();
    })
    .catch( (e: Error) => {
      this.ui.alert('Error loading Project id {{ id }} {{ error }}', {
        id: id,
        error: e.message
      })
      .then( () => {
        this.back();
      });
    });
  }

  // ----------------------------------------------------
  // -- common actions
  // ----------------------------------------------------

  /**
   * return to previous page
   */
   public back() {
    this.nav.pop(false, '/project/browse/default');
  }

  // ----------------------------------------------------
  // -- form actions and event handlers
  // ----------------------------------------------------

 /**
   * Reaction on document code copying
   */
  public onCodeCopied(text: string) {
    this.ui.alert('Document code: {{ code }} is successfully copied to clipboard', { code: text });
  }

  // ----------------------------------------------------
  // -- Section 1: Project Registration
  // ----------------------------------------------------

  public maximize_MasterDrawing() {
  }

  // ----------------------------------------------------
  // -- Section 3: Project Organization
  // ----------------------------------------------------

  // ----------------------------------------------------
  // -- section 4: Distribution List
  // ----------------------------------------------------

  protected distribution_lists: ProjectDistributionList[] = [];
  public distributionListColumnDefinitions: Column[] = [];
  public distributionListGridOptions: GridOption|null = null;
  public distributionListGridInstance: AngularGridInstance|null = null;

  protected initDistributionListGrid() {
    const component = this;

    this.distributionListColumnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },
      {
        id: 'title', name: 'Distribution List Title',
        field: 'title',
        fields: [ 'id', 'title' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 200,
        sortable: true,
        filterable: true,
      },

      {
        id: 'description', name: 'Description',
        field: 'description',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 200,
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 100, maxWidth: 100,
        sortable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDistributionList, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:    { label: 'Draft',     css: 'default' },
            reviewed: { label: 'Reviewed',  css: 'warning' },
            approved: { label: 'Approved',  css: 'success' }
          }
          let status = ( dataContext.status && dataContext.status.toLowerCase() ) || 'draft'; // for backward compat
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: [
            { value: 'draft',     label: 'Draft'     },
            { value: 'reviewed',  label: 'Reviewed'  },
            { value: 'approved',  label: 'Approved'  }
          ]
        }
      },
      /*
      {
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 150,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: [
            {
              name: 'remove',
              title: 'Remove this list',
              css: 'btn-warning',
              icon: 'pli-trash',
              visible: function(row: number, cell: number, dataContext: ProjectDistributionList, columnDef: Column, grid: SlickGrid): boolean {
                return component.api.can('delete', 'distribution_list', component.project, dataContext);
              },
              click: async (row: number, col: number, dataContext: ProjectDistributionList, config: DataGridButton, grid: SlickGrid) => {
                if ( ! await this.ui.confirm('Delete this contact list?') || ! dataContext.id ) return;
                await component.server.silent().destroy('projects/distribution_lists', dataContext.id);
                component.silent = false;
                component.distributionListGridInstance && component.distributionListGridInstance.extensionService.refreshBackendDataset();
              }
            }
          ]
        }
      }
      */
    ];

    this.distributionListGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.distributionListColumnDefinitions,
          datasetName: 'project_distribution_lists',
          persistenceFilteringOptions: [
            { field: 'project_id', operator: 'EQ', value: this.project.id?.toString() || null }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: async (query: string): Promise<GraphqlPaginatedResult|undefined> => {
          try {
            const res: object_t = await this.graphqlServer.sendQuery({query: query});
            const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
            this.distribution_lists = re.data['project_distribution_lists'].nodes.map( (d: object_t) => this.api.createDistributionList(d) );
            return re;
          }
          catch (error: unknown) {
            this.ui.alert( (error as Error).message, undefined, 'Error!');
            console.error('GrqphQL error', error);
          }
          return;
        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      presets: {
        columns: [
          { columnId: 'title' },
          { columnId: 'description' },
          { columnId: 'status' },
        ]
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'DistributionList'
      },

      enableSorting: true,

      rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#distribution-list-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
    };
  }

  public onDistributionListGridReady(event: Event) {
    this.distributionListGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public async onDistributionListGridSelectRow(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const list = this.distributionListGridInstance?.dataView.getItemByIdx<ProjectDistributionList>(selectedRow);
    if ( ! list ) {
      console.warn(`Distribution List on row ${selectedRow} could not be found!`);
      return;
    }
    this.distributionList = this.api.createDistributionList( await this.server.show('projects/distribution_lists', list.id!, {
      with: 'internals,internals.user,contacts'
    }) );
    this.distributionListDialog.show();
  }

  public addNewDistributionList(): void {
    if ( ! this.project.id ) {
      this.ui.alert('Please save the project before add new distribution list..');
      return;
    }
    this.distributionList = this.api.createDistributionList({ project_id: this.project.id });
    this.distributionListDialog.show();
  }

  public async updateInternalDistributionList(event: Event, member: InternalProjectRole) {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( (event.target as HTMLInputElement)['checked'] ) {
      this.distributionList.internals.push(member);
      this.distributionList.internals = this.distributionList.internals.filter( (r, i, a) => a.indexOf(r) === i ); // make it unique

      // add member, if the list is already existing in db, otherwise, added after creation below
      if ( this.distributionList.id ) {
        //await this.server.silent().request('projlib.distribution_list.add_internal', {
        await this.server.request('projlib.distribution_list.add_internal', {
            list_id: this.distributionList.id,
          role_id: member.id
        });
      }

    }
    else {
      const index = this.distributionList.internals.indexOf(member);
      if ( index >= 0 ) {
        this.distributionList.internals.splice(index, 1);
      }

      // remove member, if the list is already existing in db
      if ( this.distributionList.id ) {
        //await this.server.silent().request('projlib.distribution_list.remove_internal', {
        await this.server.request('projlib.distribution_list.remove_internal', {
          list_id: this.distributionList.id,
          role_id: member.id
        });
      }
    }
  }

  public async updateExternalDistributionList(event: Event, member: ExternalProjectRole) {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( (event.target as HTMLInputElement)['checked'] ) {
      this.distributionList.contacts.push(member.contact);
      this.distributionList.contacts = this.distributionList.contacts.filter( (r, i, a) => a.indexOf(r) === i ); // make it unique

      // add member, if the list is already existing in db, otherwise, added after creation below
      if ( this.distributionList.id ) {
        //await this.server.silent().request('projlib.distribution_list.add_external', {
        await this.server.request('projlib.distribution_list.add_external', {
            list_id: this.distributionList.id,
          contact_id: member.contact_id
        });
      }
    }
    else {
      const index = this.distributionList.contacts.indexOf(member.contact);
      if ( index >= 0 ) {
        this.distributionList.contacts.splice(index, 1);
      }
      // remove member, if the list is already existing in db
      if ( this.distributionList.id ) {
        //await this.server.silent().request('projlib.distribution_list.remove_external', {
        await this.server.request('projlib.distribution_list.remove_external', {
          list_id: this.distributionList.id,
          contact_id: member.contact_id
        });
      }
    }
  }

  public distributionList: ProjectDistributionList|null = null;
  public async reviewDistributionList() {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( ! this.distributionList.id ) {
      console.warn('Active distribution list is not saved yet!');
      return;
    }
    if ( ! await this.ui.confirm('Mark this list as reviewed?') ) {
      return;
    }
    this.distributionList.status = 'reviewed';
    await this.server.update('projects/distribution_lists', this.distributionList.id, this.distributionList);
    //this.silent = false;
    this.distributionListGridInstance?.extensionService.refreshBackendDataset();
    this.distributionListDialog.hide();
  }

  public async approveDistributionList() {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( ! this.distributionList.id ) {
      console.warn('Active distribution list is not saved yet!');
      return;
    }
    if ( ! await this.ui.confirm('Approve this list?') ) {
      return;
    }
    this.distributionList.status = 'approved';
    await this.server.update('projects/distribution_lists', this.distributionList.id, this.distributionList);
    //this.silent = false;
    this.distributionListGridInstance?.extensionService.refreshBackendDataset();
    this.distributionListDialog.hide();
  }

  public async saveDistributionList() {
    if ( ! this.project.id ) {
      this.ui.alert('Please save project first.');
      return;
    }
    const errors = await this.ui.validateForm(this.distributionListForm);
    if ( ! this.distributionList || Object.keys(errors).length > 0 ) {
      this.ui.alert('Distribution List information is not completed! Please check.');
      return;
    }

    let res: object_t
    if ( ! this.distributionList.id ) {
      res = await this.server.create('projects/distribution_lists', {
        title: this.distributionList.title,
        description: this.distributionList.description,
        project_id: this.project.id,
        author_id: this.session.currentUser!.id
      });

      // add members
      this.distributionList.internals.forEach( async (r) => {
        await this.server.request('projlib.distribution_list.add_internal', {
          list_id: res['id'],
          role_id: r.id
        });
      });
      this.distributionList.contacts.forEach( async (c) => {
        await this.server.request('projlib.distribution_list.add_external', {
          list_id: res['id'],
          contact_id: c.id
        });
      });

    }
    else {
      res = await this.server.update('projects/distribution_lists', this.distributionList.id, {
        title: this.distributionList.title,
        describe: this.distributionList.description
      });
    }

    // refresh
    this.distributionList = await this.api.createDistributionList(this.server.show('projects/distribution_lists', res['id']));
    this.distributionListDialog.hide();
    //this.silent = false;
    this.distributionListGridInstance?.extensionService.refreshBackendDataset();
  }

  public indexOfInternalRole(value: InternalProjectRole, array: InternalProjectRole[]): number {
    return array.findIndex( (v) => v.user_id == value.user_id );
  }

  public indexOfExternalRole(value: ExternalProjectRole, array: ExternalProjectRole[]): number {
    return array.findIndex( (v) => v.contact_id == value.contact_id );
  }

  public indexOfContact(value: ExternalContact, array: ExternalContact[]): number {
    return array.findIndex( (v) => v.id == value.id );
  }

  // ----------------------------------------------------
  // -- section 5: Project Document
  // ----------------------------------------------------

  public projectDocumentColumnDefinitions: Column[] = [];
  public projectDocumentGridOptions: GridOption|null = null;
  public projectDocumentGridInstance: AngularGridInstance|null = null;

  protected initProjectDocumentsGrid() {
    const component = this;

    this.projectDocumentColumnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        fields: [
          // include all required data here
          'revisions.id', 'revisions.revision',
          'revisions.attachments.id', 'revisions.attachments.file_name', 'revisions.attachments.file_size', 'revisions.attachments.download_url',
          'revisions.uploader_id', 'revisions.approver_id', 'revisions.reviewer_id'
        ],
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'title', name: 'Project Document Title',
        field: 'title',
        fields: [ 'prefix', 'title' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 250,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string  => {
          return dataContext.prefix + ' ' + dataContext.title;
        },

        sortable: true,
        filterable: true,
      },

      {
        id: 'approve_date', name: 'Approve Date',
        field: 'revisions.approve_date',
        type: FieldType.dateIso,
        cssClass: 'text-center', minWidth: 100, maxWidth: 120,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          if ( dataContext.revisions.length == 0 ) {
            return 'n/a';
          }
          const s = DatetimeMomentFormatter(row, cell, dataContext.approve_date || null, columnDef, dataContext, grid);
          return s.toString();
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string  => {
          if ( dataContext.revisions.length == 0 ) {
            return 'n/a';
          }
          const s = DatetimeMomentFormatter(row, cell, dataContext.revisions[0].approve_date || null, columnDef, dataContext, grid);
          return s.toString();
        },
        params: 'D MMM YYYY', // for dateTimeMoment
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 100, maxWidth: 100,
        sortable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDocument, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:     { label: 'Draft',     css: 'default' },
            submitted: { label: 'Submitted', css: 'warning' },
            reviewed:  { label: 'Reviewed',  css: 'warning' },
            approved:  { label: 'Approved',  css: 'success' }
          }
          let status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${labels[status]?.css || 'info'}">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: [
            { value: 'draft',     label: 'Draft'     },
            { value: 'submitted', label: 'Submitted' },
            { value: 'reviewed',  label: 'Reviewed'  },
            { value: 'approved',  label: 'Approved'  }
          ]
        }
      },

      {
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 150,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: [
            {
              name: 'edit',
              title: 'Edit this document',
              css: 'btn-default',
              icon: 'pli-pencil',
              visible: function(row: number, cell: number, dataContext: ProjectDocument, columnDef: Column, grid: SlickGrid): boolean {
                return component.api.can(['save', 'review', 'approve'], 'document', component.project, dataContext);
              },
              click: (row: number, col: number, dataContext: ProjectDocument, config: DataGridButton, grid: SlickGrid) => {
                component.nav.push(['project/document/edit', dataContext.id]);
              }
            },
            {
              name: 'revision',
              title: 'Add new Revision',
              css: 'btn-default',
              icon: 'pli-file-edit',
              visible: function(row: number, cell: number, dataContext: ProjectDocument|ProjectDrawing, columnDef: Column, grid: SlickGrid): boolean {
                return component.api.can('revision', 'document', component.project, dataContext);
              },
              click: (row: number, col: number, dataContext: ProjectDocument|ProjectDrawing, config: DataGridButton, grid: SlickGrid) => {
                component.nav.push(['project/document/edit', dataContext.id]);
              }
            },
            {
              name: 'add',
              title: 'Add to release bundle',
              css: 'btn-default',
              icon: 'pli-add-cart',
              visible: function(row: number, cell: number, dataContext: ProjectDocument|ProjectDrawing, columnDef: Column, grid: SlickGrid): boolean {
                return dataContext.status == 'approved' && ! component.isInBundle(dataContext);
              },
              click: (row: number, col: number, dataContext: any, config: DataGridButton, grid: SlickGrid) => {
                component.addToBundle(component.project_documents[row])
                grid.invalidateRow(row);
                grid.render();
              }
            },
            {
              name: 'remove',
              title: 'Remove from release bundle',
              css: 'btn-warning',
              icon: 'pli-remove-cart',
              visible: function(row: number, cell: number, dataContext: ProjectDocument, columnDef: Column, grid: SlickGrid): boolean {
                return dataContext.status == 'approved' && component.isInBundle(dataContext);
              },
              //this.editButtonVisible.bind(this),
              click: (row: number, col: number, dataContext: any, config: DataGridButton, grid: SlickGrid) => {
                component.removeFromBundle(dataContext);
                grid.invalidateRow(row);
                grid.render();
              }
            },
          ]
        }
      }
    ];

    this.projectDocumentGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.projectDocumentColumnDefinitions,
          datasetName: 'project_documents',
          persistenceFilteringOptions: [
            //{ field: 'project_id', operator: 'EQ', value: this.project.id?.toString() || null },
            //{ field: 'user', operator: 'EQ', value: this.session.currentUser?.id.toString() || null }
            { field: 'accessibility', operator: 'EQ', value: JSON.stringify({ project_id: this.project.id?.toString() || null, user_id: this.session.currentUser?.id.toString() || null }) }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: async (query: string): Promise<GraphqlPaginatedResult|undefined> => {
          try {
            const res: object_t = await this.graphqlServer.sendQuery({query: query});
            const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
            this.project_documents = re.data['project_documents'].nodes.map( (d: object_t) => this.api.createProjectDocument(d) );
            return re;
          }
          catch (error: unknown ) {
            this.ui.alert( (error as Error).message, undefined, 'Error!');
            console.error('GrqphQL error', error);
          }
          return;
        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      presets: {
        columns: [
          { columnId: 'title' },
          { columnId: 'approve_date' },
          { columnId: 'status' },
          { columnId: 'actions' }
        ]
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Project'
      },

      enableSorting: true,

      rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#project-doc-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
    };
  }

  public onProjectDocumentGridReady(event: Event) {
    this.projectDocumentGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onProjectDocumentGridSelectRow(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const doc = this.projectDocumentGridInstance?.dataView.getItemByIdx<ProjectDocument>(selectedRow);
    if ( ! doc || ! doc.id) {
      console.warn(`Project document on row ${selectedRow} could not be found!`);
      return;
    }
    this.viewDocument(doc);
  }

  public async viewDocument(doc: ProjectDocument) {
    this.selectedDocument = this.api.createProjectDocument(await this.server.show('projects/documents', doc.id!, {
      with: 'revisions,revisions.uploader,revisions.reviewer,revisions.approver'
    }));
    this.documentViewDialog.show();
  }

  public addNewProjectDocument(): void {
    this.nav.push({
      commands: ['/project/document/edit/new'],
      extras: {
        queryParams: {
          project_id: this.project.id
        }
      }
    });
  }

  // ----------------------------------------------------
  // -- Section 6: Drawings
  // ----------------------------------------------------

  public projectDrawingColumnDefinitions: Column[] = [];
  public projectDrawingGridOptions: GridOption|null = null;
  public projectDrawingGridInstance: AngularGridInstance|null = null;

  public asProjectDrawing(doc:ProjectDocument|ProjectDrawing): ProjectDrawing|null {
    return this.api.isProjectDrawing(doc) ? doc as ProjectDrawing : null;
  }

  public asProjectDocument(doc:ProjectDocument|ProjectDrawing): ProjectDocument|null {
    return this.api.isProjectDocument(doc) ? doc as ProjectDocument : null;
  }

  protected initProjectDrawingsGrid() {
    const component = this;

    this.projectDrawingColumnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        fields: [
          // include all required data here
          'last_approve_date', 'status',
          'revisions.id',
          'revisions.attachments.id', 'revisions.attachments.file_name', 'revisions.attachments.file_size', 'revisions.attachments.download_url',
          'revisions.revision', 'revisions.uploader_id',
          'revisions.reviewer_id', 'revisions.review_date',
          'revisions.approver_id', 'revisions.approve_date'
        ],
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'drawing_no', name: 'Drawing No.',
        field: 'drawing_no',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 200, maxWidth: 220,
        sortable: true,
        filterable: true,
      },

      {
        id: 'system', name: 'System',
        field: 'system',
        type: FieldType.string,
        cssClass: 'text-center', minWidth: 70, maxWidth: 70,
        sortable: true,
        filterable: true,
      },

      {
        id: 'title', name: 'Title',
        field: 'title',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 300,
        sortable: false,
        filterable: true,
      },

      {
        id: 'stage', name: 'Stage',
        field: 'stage',
        type: FieldType.string,
        cssClass: 'text-center', minWidth: 150, maxWidth: 150,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string  => {
          return this.api.options['drawing_stage'][value] || value;
        },
        sortable: true,
        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: this.api.getOptionsAsGridFilter('drawing_stage'),
        }
      },

      {
        id: 'revision', name: 'Revision',
        field: 'revisions.revision',
        type: FieldType.integer,
        cssClass: 'text-center', minWidth: 100, maxWidth: 100,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string  => {
          return dataContext.revisions[0].revision.toString() ;
        },
        sortable: false,
        filterable: false
      },

      {
        id: 'approve_date', name: 'Approve Date',
        field: 'last_approve_date',
        type: FieldType.dateIso,
        cssClass: 'text-center', minWidth: 100, maxWidth: 120,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string => {
          let s = DatetimeMomentFormatter(row, cell, dataContext.approve_date || null, columnDef, dataContext, grid);
          return s.toString();
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string  => {
          let s = DatetimeMomentFormatter(row, cell, dataContext.approve_date || null, columnDef, dataContext, grid);
          return s.toString();
        },
        params: 'D MMM YYYY', // for dateTimeMoment
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 100, maxWidth: 100,
        sortable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDrawing, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:     { label: 'Draft',     css: 'default' },
            submitted: { label: 'Submitted', css: 'warning' },
            reviewed:  { label: 'Reviewed',  css: 'warning' },
            approved:  { label: 'Approved',  css: 'success' }
          }
          let status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${ labels[status]?.css || 'info' }">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: this.api.getOptionsAsGridFilter('doc_status'),
          /*
          collection: [
            { value: 'draft',     label: 'Draft'     },
            { value: 'reviewed',  label: 'Reviewed'  },
            { value: 'approved',  label: 'Approved'  }
          ]
          */
        }
      },

      {
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 150,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: [
            {
              name: 'edit',
              title: 'Edit this drawing',
              css: 'btn-default',
              icon: 'pli-pencil',
              visible: function(row: number, cell: number, dataContext: ProjectDocument|ProjectDrawing, columnDef: Column, grid: SlickGrid): boolean {
                return component.api.can(['save', 'review', 'approve'], 'drawing', component.project, dataContext);
              },
              click: (row: number, col: number, dataContext: ProjectDocument|ProjectDrawing, config: DataGridButton, grid: SlickGrid) => {
                component.nav.push(['project/drawing/edit', dataContext.id]);
              }
            },
            {
              name: 'revision',
              title: 'Add new Revision',
              css: 'btn-default',
              icon: 'pli-file-edit',
              visible: function(row: number, cell: number, dataContext: ProjectDocument|ProjectDrawing, columnDef: Column, grid: SlickGrid): boolean {
                return component.api.can('revision', 'drawing', component.project, dataContext);
              },
              click: (row: number, col: number, dataContext: ProjectDocument|ProjectDrawing, config: DataGridButton, grid: SlickGrid) => {
                component.nav.push(['project/drawing/edit', dataContext.id]);
              }
            },
            {
              name: 'add',
              title: 'Add to release bundle',
              css: 'btn-default',
              icon: 'pli-add-cart',
              visible: function(row: number, cell: number, dataContext: ProjectDocument|ProjectDrawing, columnDef: Column, grid: SlickGrid): boolean {
                return dataContext.status == 'approved' && ! component.isInBundle(dataContext);
              },
              click: (row: number, col: number, dataContext: any, config: DataGridButton, grid: SlickGrid) => {
                component.addToBundle(component.project_drawings[row]);
                grid.invalidateRow(row);
                grid.render();
              }
            },
            {
              name: 'remove',
              title: 'Remove from release bundle',
              css: 'btn-warning',
              icon: 'pli-remove-cart',
              visible: function(row: number, cell: number, dataContext: ProjectDocument|ProjectDrawing, columnDef: Column, grid: SlickGrid): boolean {
                return dataContext.status == 'approved' && component.isInBundle(dataContext);
              },
              //this.editButtonVisible.bind(this),
              click: (row: number, col: number, dataContext: any, config: DataGridButton, grid: SlickGrid) => {
                component.removeFromBundle(dataContext);
                grid.invalidateRow(row);
                grid.render();
              }
            },
          ]
        }
      }

    ];

    this.projectDrawingGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.projectDocumentColumnDefinitions,
          datasetName: 'project_drawings',
          persistenceFilteringOptions: [
            //{ field: 'project_id', operator: 'EQ', value: this.project.id?.toString() || null },
            //{ field: 'user', operator: 'EQ', value: this.session.currentUser?.id.toString() || null }
            { field: 'accessibility', operator: 'EQ', value: JSON.stringify({ project_id: this.project.id?.toString() || null, user_id: this.session.currentUser?.id.toString() || null }) }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: async (query: string): Promise<GraphqlPaginatedResult|undefined> => {
          try {
            const res: object_t = await this.graphqlServer.sendQuery({query: query});
            const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
            //this.project_drawings = re.data['project_drawings'].nodes.map( (d: object_t) => this.api.createProjectDrawing(d) );
            re.data['project_drawings'].nodes = re.data['project_drawings'].nodes.map( (d: object_t) => d = this.api.createProjectDrawing(d) );
            this.project_drawings = re.data['project_drawings'].nodes;
            return re;
          }
          catch (error: unknown) {
            this.ui.alert( (error as Error).message, undefined, 'Error!');
            console.error('GrqphQL error', error);
          }
          return;
        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      presets: {
        columns: [
          { columnId: 'drawing_no' },
          { columnId: 'system' },
          { columnId: 'title' },
          { columnId: 'stage' },
          { columnId: 'revision' },
          { columnId: 'approve_date' },
          { columnId: 'status' },
          { columnId: 'actions' }
        ]
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'ProjectDrawings'
      },

      enableSorting: true,

      rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#project-drawing-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
    };
  }

  public onProjectDrawingGridReady(event: Event) {
    this.projectDrawingGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onProjectDrawingGridSelectRow(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const drawing = this.projectDrawingGridInstance?.dataView.getItemByIdx<ProjectDrawing>(selectedRow);
    if ( ! drawing || ! drawing.id ) {
      console.warn(`Project drawing on row ${selectedRow} could not be found!`);
      return;
    }
    this.viewDrawing(drawing);
  }

  public async viewDrawing(drawing: ProjectDrawing) {
    const res = await this.server.show('projects/drawings', drawing.id!, {
      //with: 'reviewer,approver,drawings',
      with: 'drawings',
      appends: 'masters'
    });
    this.selectedDrawing = this.api.createProjectDrawing(res);
    this.selectedDrawing.revisions.forEach( async (d: ProjectDrawingRevision) => {
      if ( d.id ) {
        d.masters = await this.api.loadMasters(d.id, 'drawing');
      }
    });
    this.drawingViewDialog.show();
  }

  public selectedMasterForShowing: MasterDrawing|null = null;
  public showMaster(master: MasterDrawing) {
    this.selectedMasterForShowing = master;
    this.drawingViewDialog.show();
  }

  // for project drawing to draw polygon
  public selectedMasterForPolygon: MasterDrawing|null = null;
  public selectMaster(master: MasterDrawing) {
    this.selectedMasterForPolygon = master;
    this.editPolygonDialog.show();
  }

  public addNewProjectDrawing(): void {
    this.nav.push({
      commands: ['project/drawing/edit/new'],
      extras: {
        queryParams: {
          project_id: this.project.id
        }
      }
    });
  }

  // ----------------------------------------------------
  // -- Section 7: Release Bundle
  // ----------------------------------------------------

  // bundle info
  public releaseBundle: ReleaseBundle;
  public bundle_file_excludes: Attachment[] = [];
  public bundle_contents: (ProjectDocument|ProjectDrawing)[] = [];

  public extra_email: string = '';
  public target_distribution_list: ProjectDistributionList|null = null;
  public lookup_bundle_distribution_lists: LookupItem<ProjectDistributionList>[] = [];
  public selected_recipients: ReleaseBundleRecipient[] = [];

  protected resetBundle() {
    this.releaseBundle = this.api.createReleaseBundle({ project_id: this.project.id || 0 }); // reset bundle
    this.bundle_contents = [];

    this.bundle_file_excludes = [];

    this.target_distribution_list = null;
    this.extra_email = '';
    this.lookup_bundle_distribution_lists = [];
    this.selected_recipients = [];

    this.projectDocumentGridInstance?.slickGrid.invalidateAllRows();
    this.projectDocumentGridInstance?.slickGrid.render();

    this.projectDrawingGridInstance?.slickGrid.invalidateAllRows();
    this.projectDrawingGridInstance?.slickGrid.render();

    this.bundleViewDialog.hide();
  }

  /**
   * review release bundle
   */
  public reviewBundle() {
    this.bundleViewDialog.show();
  }

  /**
   * save (and send) the bundle
   */
  public async saveBundle() {
    if ( ! this.releaseBundle.message || this.releaseBundle.message.length <= 0 ) {
      this.ui.alert('Please provide a message!');
      return;
    }

    if ( ! this.project.id || ! await this.ui.confirm('Release this bundle?') ) {
      return;
    }

    // excludes files
    let files: Attachment[] = [];
    const drawings: number[] = [];
    this.bundle_contents.forEach( d => {
      if ( this.api.isProjectDocument(d) ) {
        files = files.concat((d as ProjectDocument).revisions[0].attachments);
      }
      if ( this.api.isProjectDrawing(d) ) {
        const dwg = (d as ProjectDrawing);
        if ( dwg.revisions.length > 0) {
          files = files.concat(dwg.revisions[0].attachments);
          if ( dwg.revisions[0].id ) {
            drawings.push(dwg.revisions[0].id);
          }
        }
      }
    });

    this.releaseBundle.files = this.utils.array_utils.unique(files.filter( f => this.bundle_file_excludes.findIndex( _f => _f.id === f.id ) < 0 ));
    const data =  {
      id: this.releaseBundle.id,
      subject: this.releaseBundle.subject,
      recipients: this.releaseBundle.recipients.map( r => ({ email: r.email })),
      message: this.releaseBundle.message,
      files: this.releaseBundle.files,
      drawings: drawings, // for drawing lists generation
      project_id: this.releaseBundle.project_id,
      author_id: this.releaseBundle.author_id
    };

    await this.server.create('projects/release_bundles', data);

    // reset all
    this.resetBundle();
  }

  /**
   * check, if doc is in bundle
   */
  protected isInBundle(doc: ProjectDocument|ProjectDrawing): boolean {
    if ( !! (<ProjectDrawing>doc).drawing_no ) {
      // as a ProjectDrawing
      return this.bundle_contents
              .filter( d => !! (<ProjectDrawing>d).drawing_no )
              .filter( d => d.id == doc.id )
              .length > 0;
    }
    // as a ProjectDocument
    return this.bundle_contents
      .filter( c => ! (<ProjectDrawing>c).drawing_no )
      .filter( c => c.id == doc.id )
      .length > 0;
  }

  /**
   * add doc to bundle
   */
  public addToBundle(doc: ProjectDocument|ProjectDrawing) {
    if ( ! this.isInBundle(doc) ) {
      this.bundle_contents.push(doc);
    }
  }

  /**
   * remove doc from bundle
   */
  public async removeFromBundle(doc: ProjectDocument|ProjectDrawing) {
    if ( this.bundle_contents.length <= 1 ) {
      if ( ! await this.ui.confirm('Removing this document will cancel this bundle. Continue?') ) {
        return;
      }
    }
    else if ( ! await this.ui.confirm('Remove this document from release?') ) {
      return;
    }

    const index = this.bundle_contents.findIndex( c => (<ProjectDrawing>c).drawing_no === (<ProjectDrawing>doc).drawing_no && c.id == doc.id );
    if ( index >= 0 ) {
      this.bundle_contents.splice(index, 1);
    }

    this.projectDocumentGridInstance?.slickGrid.invalidateAllRows();
    this.projectDocumentGridInstance?.slickGrid.render();

    this.projectDocumentGridInstance?.slickGrid.invalidateAllRows();
    this.projectDocumentGridInstance?.slickGrid.render();

    if ( this.bundle_contents.length == 0 ) {
      this.resetBundle();
    }
  }

  /**
   * update excluded documents list
   */
  public updateBundleFileExcludes(event: Event, target: Attachment) {
    if ( ! event.target ) return;

    if ( (event.target as HTMLInputElement).checked ) {
      const index = this.bundle_file_excludes.indexOf(target);
      if ( index >= 0 ) this.bundle_file_excludes.splice(index, 1);
    }
    else {
      this.bundle_file_excludes.push(target);
    }
  }

  /**
   * Lookup distribution list by name
   */
  public async lookupDistributionList(keyword: string) {
    const res: object_t[] = await this.server.lookup('projects/distribution_lists', {
      project_id: this.project.id,
      status: 'approved',
      keyword: keyword,
    }, 5);

    this.lookup_bundle_distribution_lists = res.map( d => {
      let list = this.api.createDistributionList(d);
      return {
        label: list.title,
        value: list
      }
    });
  }

  /**
   * event handler for distribution list lookup
   */
  public async selectDistributionList(event: LookupEvent<ProjectDistributionList>) {
    if ( event.value.value === null ) {
      return;
    }
    const id = event.value.value.id;
    if ( ! id ) {
      console.warn('Distribution List do not have id?!?');
      return;
    }
    const res: object_t[] = await this.server.show('projects/distribution_lists', id, {
      with: 'internals,internals.user,contacts'
    });
    const list = this.api.createDistributionList(res);
    this.target_distribution_list = list;
    this.selected_recipients = list.internals
                                .filter(r => r.user && r.user.email )
                                .map( r => this.api.createReleaseBundleRecipient({ email: r.user?.email }))
                                .concat(
                                  list.contacts.map( c => this.api.createReleaseBundleRecipient({ email: c.email }) )
                                );
    this.selectDistributionListModal.show();
  }

  /**
   * update bundle recipient from distribution list
   */

  public async updateSelectedRecipients(email: string, active: boolean) {
    const index = this.selected_recipients.findIndex( r => r.email == email );
    if ( index < 0 ) {
      console.error('Cannot find the target item in the list', email, this.selected_recipients);
      return;
    }

    if ( ! active ) {
      if ( await this.ui.confirm('Remove "{{ email }}" from list?', { email: email }) ) {
        this.selected_recipients.splice(index, 1);
      }
    }
    else if ( this.releaseBundle.recipients.findIndex( r => r.email == email ) >= 0 ) {
      this.ui.alert("{{ email }} is already in the list.", { email: email });
    }
    else {
      this.selected_recipients.push( this.api.createReleaseBundleRecipient({ email: email }));
    }
  }

  public addDistributionListToRecipient() {
    this.releaseBundle.recipients = this.releaseBundle.recipients.concat(this.selected_recipients);
    this.selectDistributionListModal.hide();
  }


  /**
   * Add external email address to the release recipients
   */
  public addEmailToDistribution() {
    if ( this.extra_email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) === null ) {
      this.ui.alert('Please enter a valid email address.');
      return;
    }

    if ( this.releaseBundle.recipients.findIndex( r => r.email === this.extra_email ) >= 0 ) {
      this.ui.alert('{{ email }} is already in the list.', { email: this.extra_email });
    }
    else {
      this.releaseBundle.recipients.push(this.api.createReleaseBundleRecipient({ email: this.extra_email, extra: true }));
    }
    this.extra_email = '';
  }

  public async removeRecipient(index: number) {
    const recipient = this.releaseBundle.recipients[index] || null;
    if ( ! recipient ) {
      console.error('Recipient index out of bound!', this.releaseBundle.recipients, index);
      return;
    }
    if ( await this.ui.confirm('Remove {{ email }} from list?', { email: this.releaseBundle.recipients[index].email }) ) {
      this.releaseBundle.recipients.splice(index, 1);
    }
  }

  // ----------------------------------------------------
  // -- Section 8: Release Bundle Tempalte
  // ----------------------------------------------------

  /*
  GET|HEAD        services/tpmtool/v1.0/spw/projects/release_bundle_templates .................................................................................... release_bundle_templates.index › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@index
  POST            services/tpmtool/v1.0/spw/projects/release_bundle_templates .................................................................................... release_bundle_templates.store › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@store
  GET|HEAD        services/tpmtool/v1.0/spw/projects/release_bundle_templates/count ................................................................................. generated::PODqYpMDc2xzkrI6 › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@count
  GET|HEAD        services/tpmtool/v1.0/spw/projects/release_bundle_templates/create ........................................................................... release_bundle_templates.create › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@create
  ANY             services/tpmtool/v1.0/spw/projects/release_bundle_templates/lookup ............................................................................... generated::C33qOtXbpdkQZTsk › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@lookup
  GET|HEAD        services/tpmtool/v1.0/spw/projects/release_bundle_templates/purge/{id} ............................................................................ generated::xLsMmJ2DPlHioZok › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@purge
  GET|HEAD        services/tpmtool/v1.0/spw/projects/release_bundle_templates/restore/{id} ........................................................................ generated::EjbFs043LDg45Tlh › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@restore
  GET|HEAD        services/tpmtool/v1.0/spw/projects/release_bundle_templates/{release_bundle_template} ............................................................ release_bundle_templates.show › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@show
  PUT|PATCH       services/tpmtool/v1.0/spw/projects/release_bundle_templates/{release_bundle_template} ........................................................ release_bundle_templates.update › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@update
  DELETE          services/tpmtool/v1.0/spw/projects/release_bundle_templates/{release_bundle_template} ...................................................... release_bundle_templates.destroy › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@destroy
  GET|HEAD        services/tpmtool/v1.0/spw/projects/release_bundle_templates/{release_bundle_template}/edit ....................................................... release_bundle_templates.edit › App\Modules\SiamPiwat\Http\Controllers\ProjectReleaseBundleTemplate@edit
  */

  protected bundle_templates: ReleaseBundleTemplate[] = [];
  public bundleTemplate: ReleaseBundleTemplate|null = null;
  public bundleTemplatesColumnDefinitions: Column[] = [];
  public bundleTemplatesGridOptions: GridOption|null = null;
  public bundleTemplatesGridInstance: AngularGridInstance|null = null;

  protected initBundleTempaltesGrid() {
    const component = this;

    this.bundleTemplatesColumnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'subject', name: 'Subject',
        field: 'subject',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 300,
        sortable: true,
        filterable: true,
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 100, maxWidth: 100,
        sortable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ReleaseBundleTemplate, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:    { label: 'Draft',     css: 'default' },
            reviewed: { label: 'Reviewed',  css: 'warning' },
            approved: { label: 'Approved',  css: 'success' }
          }
          let status = ( dataContext.status && dataContext.status.toLowerCase() ) || 'draft'; // for backward compat
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: [
            { value: 'draft',     label: 'Draft'     },
            { value: 'reviewed',  label: 'Reviewed'  },
            { value: 'approved',  label: 'Approved'  }
          ]
        }
      },
      {
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 150,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: [
            {
              name: 'remove',
              title: 'Remove this template',
              css: 'btn-warning',
              icon: 'pli-trash',
              visible: function(row: number, cell: number, dataContext: ReleaseBundleTemplate, columnDef: Column, grid: SlickGrid): boolean {
                return component.api.can('delete', 'bundle_template', component.project, dataContext);
              },
              click: async (row: number, col: number, dataContext: ReleaseBundleTemplate, config: DataGridButton, grid: SlickGrid) => {
                if ( ! await this.ui.confirm('Delete this budle template?') || ! dataContext.id ) return;
                //await component.server.silent().destroy('-- projects/distribution_lists --', dataContext.id);
                //component.silent = false;
                await component.server.destroy('-- projects/distribution_lists --', dataContext.id);
                component.bundleTemplatesGridInstance && component.bundleTemplatesGridInstance.extensionService.refreshBackendDataset();
              }
            }
          ]
        }
      }
    ];

    this.bundleTemplatesGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.bundleTemplatesColumnDefinitions,
          datasetName: 'siampiwat_bundle_tempaltes',
          persistenceFilteringOptions: [
            { field: 'project_id', operator: 'EQ', value: this.project.id?.toString() || null }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: async (query: string): Promise<GraphqlPaginatedResult|undefined> => {
          try {
            const res: object_t = await this.graphqlServer.sendQuery({query: query});
            const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
            this.bundle_templates = re.data['siampiwat_bundle_tempaltes'].nodes.map( (d: object_t) => this.api.createReleaseBundleTemplate(d) );
            return re;
          }
          catch (error: unknown) {
            this.ui.alert( (error as Error).message, undefined, 'Error!');
            console.error('GrqphQL error', error);
          }
          return;
        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'BundleTemplates'
      },

      enableSorting: true,

      rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#bundle-template-tables',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
    };
  }

  public onBundleTemplatesGridReady(event: Event) {
    this.bundleTemplatesGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public async onBundleTemplatesGridSelectRow(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const list = this.bundleTemplatesGridInstance?.dataView.getItemByIdx<ReleaseBundleTemplate>(selectedRow);
    if ( ! list ) {
      console.warn(`Bundle template on row ${selectedRow} could not be found!`);
      return;
    }
    this.bundleTemplate = this.api.createReleaseBundleTemplate( await this.server.show('--projects/distribution_lists--', list.id!, {
      with: '--internals,internals.user,contacts--'
    }) );
    this.bundleTemplateViewDialog.show();
  }

  public addNewBundleTemplate(): void {
    if ( ! this.project.id ) {
      this.ui.alert('Please save the project before add new distribution list..');
      return;
    }
    this.bundleTemplate = this.api.createReleaseBundleTemplate({ project_id: this.project.id });
    this.bundleTemplateViewDialog.show();
  }

  public async removeDocumentFromBundleTemplate(doc: ProjectDocument) {
    //this.bundleTemplate && this.bundleTemplate.documents.splice();
  }

  public async removeDrawingFromBundleTemplate(doc: ProjectDrawing) {
    //this.bundleTemplate && this.bundleTemplate.drawing.splice();
  }

  /*
  public async updateInternalDistributionList(event: Event, member: InternalProjectRole) {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( (event.target as HTMLInputElement)['checked'] ) {
      this.distributionList.internals.push(member);
      this.distributionList.internals = this.distributionList.internals.filter( (r, i, a) => a.indexOf(r) === i ); // make it unique

      // add member, if the list is already existing in db, otherwise, added after creation below
      if ( this.distributionList.id ) {
        await this.server.silent().request('projlib.distribution_list.add_internal', {
          list_id: this.distributionList.id,
          role_id: member.id
        });
      }

    }
    else {
      const index = this.distributionList.internals.indexOf(member);
      if ( index >= 0 ) {
        this.distributionList.internals.splice(index, 1);
      }

      // remove member, if the list is already existing in db
      if ( this.distributionList.id ) {
        await this.server.silent().request('projlib.distribution_list.remove_internal', {
          list_id: this.distributionList.id,
          role_id: member.id
        });
      }
    }
  }
  */

  /*
  public async updateExternalDistributionList(event: Event, member: ExternalProjectRole) {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( (event.target as HTMLInputElement)['checked'] ) {
      this.distributionList.contacts.push(member.contact);
      this.distributionList.contacts = this.distributionList.contacts.filter( (r, i, a) => a.indexOf(r) === i ); // make it unique

      // add member, if the list is already existing in db, otherwise, added after creation below
      if ( this.distributionList.id ) {
        await this.server.silent().request('projlib.distribution_list.add_external', {
          list_id: this.distributionList.id,
          contact_id: member.contact_id
        });
      }
    }
    else {
      const index = this.distributionList.contacts.indexOf(member.contact);
      if ( index >= 0 ) {
        this.distributionList.contacts.splice(index, 1);
      }
      // remove member, if the list is already existing in db
      if ( this.distributionList.id ) {
        await this.server.silent().request('projlib.distribution_list.remove_external', {
          list_id: this.distributionList.id,
          contact_id: member.contact_id
        });
      }
    }
  }
  */

  public async reviewBundleTemplate() {
    /*
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( ! this.distributionList.id ) {
      console.warn('Active distribution list is not saved yet!');
      return;
    }
    if ( ! await this.ui.confirm('Mark this list as reviewed?') ) {
      return;
    }
    this.distributionList.status = 'reviewed';
    await this.server.update('projects/distribution_lists', this.distributionList.id, this.distributionList);
    this.silent = false;
    this.distributionListGridInstance?.extensionService.refreshBackendDataset();
    this.distributionListDialog.hide();
    */
  }

  public async approveBundleTemplate() {
    /*
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( ! this.distributionList.id ) {
      console.warn('Active distribution list is not saved yet!');
      return;
    }
    if ( ! await this.ui.confirm('Approve this list?') ) {
      return;
    }
    this.distributionList.status = 'approved';
    await this.server.update('projects/distribution_lists', this.distributionList.id, this.distributionList);
    this.silent = false;
    this.distributionListGridInstance?.extensionService.refreshBackendDataset();
    this.distributionListDialog.hide();
    */
  }

  public async saveBundleTemplate() {
    if ( ! this.project.id ) {
      this.ui.alert('Please save project first.');
      return;
    }
    /*
    const errors = await this.ui.validateForm(this.distributionListForm);
    if ( ! this.distributionList || Object.keys(errors).length > 0 ) {
      this.ui.alert('Distribution List information is not completed! Please check.');
      return;
    }

    let res: object_t
    if ( ! this.distributionList.id ) {
      res = await this.server.create('projects/distribution_lists', {
        title: this.distributionList.title,
        description: this.distributionList.description,
        project_id: this.project.id,
        author_id: this.session.currentUser!.id
      });

      // add members
      this.distributionList.internals.forEach( async (r) => {
        await this.server.request('projlib.distribution_list.add_internal', {
          list_id: res['id'],
          role_id: r.id
        });
      });
      this.distributionList.contacts.forEach( async (c) => {
        await this.server.request('projlib.distribution_list.add_external', {
          list_id: res['id'],
          contact_id: c.id
        });
      });

    }
    else {
      res = await this.server.update('projects/distribution_lists', this.distributionList.id, {
        title: this.distributionList.title,
        describe: this.distributionList.description
      });
    }

    // refresh
    this.distributionList = await this.api.createDistributionList(this.server.show('projects/distribution_lists', res['id']));
    this.distributionListDialog.hide();
    this.silent = false;
    this.distributionListGridInstance?.extensionService.refreshBackendDataset();
    */
  }
}
