import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NgForm,
  ValidationErrors
} from '@angular/forms';

import {
  config,
  DateTimeUtilsService,
  FileUtilsService,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  UserService,
  TaxonomyService,
  TreeUtilsService,
  StringUtilsService,
} from '@pinacono/common';

import {
  DropZoneComponent,
  DZFile,
  DZOptions,
  DZSuccess,
  InterpolatbleErrorMessage,
  ModalComponent,
  UIService,
} from '@pinacono/ui';

import {
  GraphQLServerService,
} from '@pinacono/slickgrid-extension';

import { IIP } from '@pinacono/iip-viewer';

import { CustomPropertyConfig } from 'src/app/common/types';
import { BasePageComponent } from 'src/app/classes/base-page.component';

import moment from 'moment-timezone';
declare let $: any;

import { ProjectLibService } from '../projects.service';
import {
  Drawing,
  MasterDrawing,
  DrawingCode,
  ProjectDrawing,
  Project,
  InternalProjectRole
} from '../types';
import { AppUser } from 'src/app/types';

/**
 * Design Note:
 *
 * - statuses:
 *
 *   draft     - [ submit ]  ->
 *   submitted - [ review ]  ->
 *   reviewed  - [ approve ] ->
 *   approved  - [ upload new revision ] ->
 *   revision draft - [ submit ] -> submitted ...
 *                  - [ remove upload ]  -> approved ...
 *
 * - revision draft = draft && drawing.drawings.length > 1
 * - allow upload one file at a time, only in first draft state and approved state
 * - allow editing data only in first draft state
 */

@Component({
  selector: 'page-projlib-drawing-edit',
  templateUrl: 'drawing.html',
  styleUrls: [ 'drawing.scss' ]
})
export class ProjectLibDrawingEditPage extends BasePageComponent implements OnInit, AfterViewInit {
  @ViewChild('mainForm') mainForm!: NgForm;
  @ViewChild('dropzone') dropzone!: DropZoneComponent;

  // keyvalue pipe comparator
  public originalOrder(): number { return 0; };

  // data
  public errors: {
    [name: string]: InterpolatbleErrorMessage | InterpolatbleErrorMessage[] | string | string[];
  } | ValidationErrors = {};

  //public drawingSystemOptions: { key: string, value: string }[] = [];
  public drawing: ProjectDrawing;
  protected project: Project|null = null;
  public teamOptions: { key: string, value: string }[] = [];

  public uploader_id: number|null = null;
  public uploader: AppUser|null   = null;

  public reviewer_id: number|null = null;
  public reviewer: AppUser|null   = null;
  public review_date: string|null = null;

  public approver_id: number|null = null
  public approver: AppUser|null   = null;
  public approve_date: string|null = null;

  public config = config;

  // dropzone
  protected defer_key: string;

  public dzConfig: DZOptions = {};
  public dzURL: string = '#';
  public alert = alert;

  // ----------------------------------------------------
  // -- life cycle
  // ----------------------------------------------------

  // constructor
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    //public validator: PinaconoValidatorsService,
    public api: ProjectLibService,
    protected server: ServerService,
    protected taxonomy: TaxonomyService,
    protected user: UserService,
    protected treeUtils: TreeUtilsService,
    protected fileUtils: FileUtilsService,
    protected stringUtils: StringUtilsService,
    protected dateTimeUtils: DateTimeUtilsService,
    protected graphqlServer: GraphQLServerService,
  ) {
    super(router, activatedRoute);
    this.drawing  = this.api.createProjectDrawing();

    // attachment
    this.defer_key = stringUtils.random(16);

    // master searching
    this.iip_base_path = config('client.drawing.iip_base_path')
    this.search_input  = this.api.createDrawing();
    //this.master        = this.api.createDrawing() as MasterDrawing;

    //this.drawingSystemOptions = this.api.options['drawing_system'];
  }

  public override ngOnInit(): void {
    //const state = this.router.getCurrentNavigation()?.extras.state || null;
    this.master_buildings_config.options = this.api.getBuildings();

    // dropzone configuration
    this.dzURL = this.server.compile( 'attachment.create', {
      content_type: this.server.rawParams('drawings'),
    }).url;
    super.ngOnInit();
  }

  public ngAfterViewInit(): void {
  }

  protected override loadData(): Promise<any> {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if ( id === null ) {
      this.nav.goto('/projlib/browse');
      return Promise.resolve();
    }

    if ( id === 'new' ) {
      const pid = this.activatedRoute.snapshot.queryParamMap.get('project_id');
      if ( ! pid || isNaN(parseInt(pid)) ) {
        this.ui.alert('Bad project ID - {{ project_id }}', { project_id: pid });
        this.back();
        return Promise.resolve();
      }

      const project_id = parseInt(pid);

      return this.server
      //.simulate(TEST_DATA.projlib.show)
      .rejectOnError(true)
      .show('projects', project_id, {
        with: [
          'internal_project_roles', 'internal_project_roles.user'
        ].join(','),
        appends: ['masters'].join(',')
      })
      .then( (res: object_t) => {
        this.drawing.project_id = project_id;
        this.project = this.api.createProject(res);
        this.drawing.project = this.project;

        this.teamOptions   = this.convertTeamsToOptions(this.api.getUserTeams(this.project, this.session.currentUser));
        const teams        = this.api.getUserTeams(this.project);
        this.document_team = teams.length > 0 ? teams[0] : this.api.core_team_name;
        this.drawing.owner_team = this.document_team;
        this.docTeamChanged();
      })
      .catch( (e: Error) => {
        this.ui.alert('Error loading Project id {{ id }} {{ error }}', {
          id: id,
          error: e.message
        })
        .then( () => {
          this.back();
        });
      });
    }

    let nid: number = parseInt(id);
    if ( isNaN(nid) ) {
      this.ui.alert(`Drawing id "${id}" could not be found!`);
      this.nav.back();
      return Promise.resolve();
    }

    // reset form
    this.clearDirty();

    return this.server
    //.simulate(TEST_DATA.projlib.proj_drawing.show)
    .rejectOnError(true)
    .show('projects/drawings', nid, {
      with: [
        'drawings', 'drawings.attachments', 'drawings.attachments.uploader',
        'project', 'project.internal_project_roles', 'project.internal_project_roles.user', 'project.masters',
        //'reviewer', 'approver',
        'action_logs', 'action_logs.user',
        'comments', 'comments.user'
      ].join(','),
      appends: [
        'masters',
        'last_revision_id',
      ].join(',')
    })
    .then( async (res: object_t) => {
      this.drawing = this.api.createProjectDrawing(res);
      this.project = this.drawing.project!;

      /* new code - to restore the reviewer/approver from previous revision to team's default */
      this.uploader_id = this.session.currentUser!.id;
      this.uploader    = this.session.currentUser;
      /* end of new code */

      this.teamOptions   = this.convertTeamsToOptions(this.api.getUserTeams(this.project, this.session.currentUser));
      this.document_team = this.drawing.owner_team;
      this.drawing.owner_team = this.document_team;
      this.docTeamChanged(false); /* new code - to restore the reviewer/approver from previous revision to team's default */
      this.refreshMasters();

      /*
      if ( this.activatedRoute.snapshot.queryParamMap.get('showSearchMasterDialog') === '1' ) {
        this.masterSearchDialog.show();
      }
      */
    })
    .catch( (e: Error) => {
      this.ui.alert('Error loading Project Drawing id {{ id }} {{ error }}', {
        id: id,
        error: e.message
      })
      .then( () => {
        this.back();
      });
    });
  }

  // ----------------------------------------------------
  // -- overriding
  // ----------------------------------------------------

  public override async refresh() {
    if ( ! this.drawing.id ) {
      super.refresh();
    }
    else {
      await this.router.navigateByUrl('/', { skipLocationChange: true });
      //this.nav.goto({ commands: [ 'project/drawing/edit/', this.drawing.id] , extras: { state: this.state } });
      // showSearchMasterDialog
      this.nav.goto({
        commands: [ 'project/drawing/edit/', this.drawing.id],
        /*
        extras: {
          queryParams: {
            showSearchMasterDialog: 1
          }
        }
        */
      });
    }
  }

  // ----------------------------------------------------
  // -- validations
  // ----------------------------------------------------

  public get dirty(): boolean {
    return this.mainForm && this.mainForm.form && this.mainForm.form.dirty;
  }

  public markDirty() {
    this.mainForm && this.mainForm.form.markAsDirty();
  }

  public clearDirty() {
    this.mainForm && this.mainForm.form.markAsPristine();
  }

  /**
   * validation logic based on action and drawing status
   * trig error message or pop error message
   */
  protected async validate(action: 'save'|'submit'|'review'|'rework'|'approve'|'drawing_no' = 'save'): Promise<boolean> {
    this.errors = {};

    switch ( action ) {
      case 'drawing_no':
        if ( ! this.drawing.drawing_no || this.drawing.drawing_no.trim().length == 0 ) {
          this.errors['drawing_no'] = `Drawing no is required.`;
          break;
        }

        const dup_id: number = await this.server.silent().request('projlib.proj_drawing.validation.dwg_no.unique', null, {
          value: this.drawing.drawing_no,
          project_id: this.drawing.project_id
        });

        if ( dup_id != 0 && dup_id != this.drawing.id ) {
          this.errors['drawing_no'] = `Drawing no is already exists in this project.`;
        }
      break;

      case 'save':
      break;

      case 'submit':
        if ( this.drawing.revisions.length == 0 ) return false;
      break;
    }

    this.errors = {
      ... await this.ui.validateForm(this.mainForm),
      ... this.errors
    };

    return Promise.resolve(Object.keys(this.errors).length == 0);
  }

  // ----------------------------------------------------
  // -- internal utilities
  // ----------------------------------------------------

  protected async refreshMasters() {
    /*
    this.drawing.drawings.forEach( async (d: Drawing) => {
      if ( d.id ) {
        d.masters = await this.api.loadMasters(d.id, 'drawing');
      }
    });
    */
   if ( this.drawing.id ) {
    this.drawing.masters = await this.api.loadMasters(this.drawing.id, 'project_drawing');
   }
  }

  /**
   * return current date/time
   */
  public get now(): Date {
    return moment().toDate();
  }

  protected addActionLog(action: string, note: string = ''): Promise<void> {
    if ( ! this.drawing.id ) {
      console.warn('Cannot add drawing action log, drawing is not saved yet');
      return Promise.resolve();
    }

    return this.server.request('projlib.proj_drawing.add_log', {
      id: this.drawing.id
    }, {
      doc_status: this.drawing.status,
      user_id: this.session.currentUser!.id,
      action: action,
      note:'revision ' + Math.max(0, this.drawing.revisions.length - 1) + ': ' + note
    })
    .then( (log: object_t) => {
      this.drawing.action_logs.push(this.api.createProjectDocumentAction(log));
    });
  }

  // ----------------------------------------------------
  // -- master binding
  // ----------------------------------------------------

  @ViewChild('polygonEditor') polygonEditor!: ModalComponent;
  @ViewChild('masterSearchDialog') masterSearchDialog!: ModalComponent;

  public search_input:Drawing;
  public iip_base_path: string = '';
  public mastersList: MasterDrawing[] = [];
  public master:MasterDrawing|null = null;
  public polygons: IIP.Polygon[] = [];


  public polygonPrototype: IIP.Coordinate[] = Array.from(IIP.DefaultPrototypePolygon);
  public async onDrawingZoom(view: IIP.ViewPort) {
    this.polygonPrototype = IIP.DefaultPrototypePolygon.map( (v: IIP.Coordinate) => ({
      x: ( v.x / view.scale.w ) * 0.75,
      y: ( v.y / view.scale.h ) * 0.75
    }));
  }

  //protected activeDrawing: Drawing|null = null;

  /*
  public editDrawingInfo(dwg: Drawing) {
    this.activeDrawing = dwg;
    this.masterSearchDialog.show();
  }
  */

  public drawingStageChange() {
    /*
    // make the IIP view show default master from project
    this.master = this.drawing.project?.master || null;
    this.drawing.master = this.drawing.project?.master;
    this.polygons = [];
    this.polygonsIsDirty = false;
    */
    this.markDirty();
  }

  public master_buildings_config: CustomPropertyConfig = {
    name: 'building',
    type: 'editable-list',
    label: 'Building',
    icon: 'fa-regular fa-building',
    options: []
  };

  public master_floors_config: CustomPropertyConfig = {
    name: 'floor',
    type: 'editable-list',
    label: 'Floor',
    icon: 'fa-regular fa-building',
    options: []
  }
  public onMasterBuildingChange(value: string): void {
    this.search_input.attr['floor'] = '';
    this.master_floors_config.options = this.api.getFloors(value);
  }

  public async searchMaster() {
    const list: MasterDrawing[] = await this.api.searchMasters(this.search_input);
    this.mastersList = list;
    this.search_input = this.api.createDrawing();
    this.polygons = [];
  }

  // -- new code

  public async showMaster(master: MasterDrawing|null = null) {
    if ( ! this.drawing.id ) {
      // note - cannot save and show masterSearchDialog since
      // the saving will refresh the screen by redirect to edit
      // the newly saved drawing.
      await this.ui.alert('Current drawing is not saved. please save it first.');
      return;
    }

    this.master = master || this.api.createMasterDrawing();
    this.polygons = this.master.pivot_polygons || [];
    if ( ! master ) {
      this.masterSearchDialog.show();
    }
    else {
      this.polygonEditor.show();
    }
  }

  public async detachMaster(master: MasterDrawing) {
    if ( await this.ui.confirm('Detach this drawing from the master?') ) {
      await this.server.request('drawings.detach', { master_id: master.id }, {
        pivot_id: master.pivot_id || null,
        supplement_type: 'project_drawing',
        supplement_id: this.drawing.id,
      });
      this.refreshMasters();
    }
  }

  public selectMaster(dwg: MasterDrawing) {
    this.master = dwg;
    this.polygons = dwg?.pivot_polygons || [];
    this.polygonsIsDirty = false;
    this.masterSearchDialog.hide();
    this.polygonEditor.show();
  }

  protected polygonsIsDirty: boolean = false;
  public markPolygonsDirty() {
    this.polygonsIsDirty = true;
  }


  /**
   * @TODO implement UI for this?
   */
  /*
  public async deleteMasterLink(master: MasterDrawing) {
    if ( ! master.pivot_id ) {
      console.warn('Master do not have renovation link! Cannot delete!');
      return;
    }
    if ( await this.ui.confirm('Delete this link?') ) {
      if ( await this.server.request('drawings.detach', { master_id: master.id }, {
        supplement_type: 'project_drawing',
        supplement_id: this.drawing.id
      }) > 0 ) {
        this.refreshMasters();
      }
    }
  }
  */

  public async savePolygon() {
    if ( ! this.drawing.id ) {
      this.ui.alert('Please save current drawing first.');
      return;
    }

    if ( !! this.master ) {
      await this.server.request('drawings.attach', { master_id: this.master.id }, {
        pivot_id: this.master.pivot_id || null,
        supplement_type: 'project_drawing',
        supplement_id: this.drawing.id,
        polygons: this.polygons.map( p => p.vertices )
      });
      this.ui.alert('Polygon(s) is successfully saved.');
      this.polygonsIsDirty = false;
      this.hidePolygonEditor();
      this.refreshMasters();
      //this.refresh();
    }
  }

  public async hidePolygonEditor() {
    if ( this.polygonsIsDirty && await this.ui.confirm('Save polygon?') ) {
      this.polygonsIsDirty = false;
      this.savePolygon();
    }
    this.master = null;
    this.polygonEditor.hide();
  }

  // ----------------------------------------------------
  // -- template API
  // ----------------------------------------------------

  // special states - use status parameter to reduce the workload from view
  public revisionDraft(status: string|null = null): boolean {
    return ( this.drawing.revisions.length > 1 && ( status || this.drawing.status ) == 'draft' );
  }

  public firstDraft(status: string|null = null): boolean {
    return ( this.drawing.revisions.length <= 1 && (status || this.drawing.status ) == 'draft' );
  }

  public convertTeamsToOptions(teams: string[]): { key: string, value: string }[] {
    return teams.map( t => ({
      key: t,
      value: t
    }));
  }

  /**
   * Update document owner team
   */
  public document_team: string = '';
  public docTeamChanged(markDirty: boolean = true) {
    const leaders: InternalProjectRole[]      = this.api.getUserWithRole(this.drawing.project!, this.document_team, 'leader');
    const coordinators: InternalProjectRole[] = this.api.getUserWithRole(this.drawing.project!, this.document_team, 'coordinator');

    this.approver    = null;
    this.approver_id = null;
    this.reviewer    = null;
    this.reviewer_id = null;

    // set leader as drawing approver
    if ( leaders.length > 0 && leaders[0].user ) {
      this.approver    = leaders[0].user;
      this.approver_id = leaders[0].user.id;
    }

    // set coordinator or leader as drawing reviewer
    if ( coordinators.length > 0 && coordinators[0].user ) {
      this.reviewer    = coordinators[0].user;
      this.reviewer_id = coordinators[0].user.id;
    }
    else if ( leaders.length > 0 && !! leaders[0].user ) {
      this.reviewer    = leaders[0].user;
      this.reviewer_id = leaders[0].user.id;
    }

    // bail out if no reviewer or approver found
    if ( ! this.reviewer || ! this.reviewer_id) {
      this.ui.alert('No reviewer found for this project!');
      return;
    }

    if ( ! this.approver || ! this.approver_id ) {
      this.ui.alert('No approver found for this project!');
      return;
    }

    // bail out if no drawing revision is available
    if ( this.drawing.revisions.length == 0 ) {
      console.warn('Drawing revision is not available');
      return;
    }

    // update last revision's reviwer, if document is not reviewed and approved yet
    if ( this.drawing.status != 'reviewed' && this.drawing.status != 'approved' ) {

      this.drawing.revisions[0].reviewer    = this.reviewer;
      this.drawing.revisions[0].reviewer_id = this.reviewer_id;
    }

    // update last revision's approver, if document is not approved yet
    if ( this.drawing.status != 'approved' ) {
      this.drawing.revisions[0].approver    = this.approver;
      this.drawing.revisions[0].approver_id = this.approver_id;
    }

    this.drawing.owner_team = this.document_team;
    markDirty && this.markDirty();
  }

  public is_submitable(status: string, attachments: Drawing[]): boolean {
    return attachments.length > 0 && this.project !== null && this.api.can('submit', 'drawing', this.project, this.drawing);
  }

  public is_reviewable(status: string): boolean {
    return this.project !== null && this.api.can('review', 'drawing', this.project, this.drawing);
  }

  public is_approvable(status: string): boolean {
    return this.project !== null && this.api.can('approve', 'drawing', this.project, this.drawing);
  }

  public is_deletable(status: string): boolean {
    return this.drawing.id !== null && this.project !== null && this.api.can('delete', 'drawing', this.project, this.drawing);
  }

  public drawing_no: DrawingCode = { a: 'AC', b: '00', c: '01', d: undefined };
  /*
  public onDrawingNoChanged(segment: string = '') {
    // change event raised before value in the model get updated
    // therefore, we validate it on the next cycle with setTimeout
    setTimeout( () => {
      this.drawing.drawing_no = Object.values(this.drawing_no).filter( (s: string|undefined) => s && s.trim().length > 0 ).join('-');
      this.markDirty();
    });
  }
  */

  /**
   * return to previous page
   */
  public back() {
    this.nav.pop(false, `/project/view/${this.drawing.project_id}`);
  }

  /**
   * delete this drawing
   */
  public delete() {
    if ( ! this.drawing.id ) return;
    this.ui.confirm('Delete this project drawing?', undefined, async () => {
      await this.server
        //.simulate(1) // @TODO remove this
        .destroy('projects/drawings', this.drawing.id!);
      this.back();
    });
  }

  /**
   * save this drawing (no validation)
   */
  protected refreshAfterUpload: boolean = true;
  public async save(refresh:boolean = true, validate: ''|boolean = true): Promise<boolean> {

    if ( validate && ! await this.validate() ) {
      this.ui.alert('Project drawing information is not completed. Please check');
      return false;
    }

    // retrieve data for saving
    const data = {
      id: this.drawing.id,
      drawing_no: this.drawing.drawing_no,
      system: this.drawing.system,
      title: this.drawing.title,
      status: this.drawing.status,
      stage: this.drawing.stage,
      type: this.drawing.type,
      description: this.drawing.description,
      project_id: this.drawing.project_id,
      owner_team: this.drawing.owner_team,
      is_project_drawing: this.drawing.is_project_drawing
    }

    if ( ! this.drawing.id ) {
      // add some default to allow draft saving
      //data.description = ( data.description && data.description.length > 0 ) ? data.description : ".";
      this.drawing = this.api.createProjectDrawing(await this.server.create('projects/drawings', data));
      await this.addActionLog('create', 'Document initialized');
    }
    else {
      this.drawing = this.api.createProjectDrawing(await this.server.update('projects/drawings', this.drawing.id, data));
    }

    if ( this.dropzone.dropzone.files.length > 0 ) {
      this.refreshAfterUpload = refresh;
      this.dropzone.upload();
    }
    else {
      if ( refresh ) await this.refresh();
    }

    return true;
  }

  /**
   * Workflow action
   */
  public async action(action: 'submit'|'review'|'rework'|'approve') {
    if ( ! this.drawing.id ) {
      console.warn('Project Drawing ID is not available. Cannot perform workflow action.');
      return;
    }
    if ( this.drawing.revisions.length <= 0 ) {
      console.warn('Last draft project drawing is not available. Cannot perform workflow action.');
      return;
    }
    if ( ! await this.validate(action) ) {
      this.ui.alert(`Cannot ${action} the project drawing. Information is not completed. Please check.`);
      return;
    }

    if ( this.dirty ) {
      if ( ! this.ui.confirm('Save changes before continue?') ) {
        return;
      }
      await this.save();
    }
    else if ( ! await this.ui.confirm(`Please confirm to ${action} the project drawing?`) ) {
      return;
    }

    const note = await this.ui.prompt('Note:');
    try {

      /** note: reviewer_id and approver_id are both overriden with current user by the server's action event handler */
      const res: object_t = await this.server.request('projlib.proj_drawing.action', {
        //id: this.drawing.id,
        id: this.drawing.revisions[0].id,
        action: action
      });

      //this.drawing = this.api.createProjectDrawing(res);
      await this.addActionLog(action, note);
      //this.refresh();
      this.back();
    }
    catch( error: any ) {
      this.ui.alert(error.message);
    }
  }

  public async deleteDrawing(dwg: Drawing) {
    if ( ! await this.ui.confirm('Delete this file?') ) return;
    await this.server.request('projlib.proj_drawing.detach', {
      id: this.drawing.id,
      drawing_id: dwg.id
    });

    if ( this.drawing.revisions.length > 1 ) {
      this.drawing.status = 'approved'; // rollback to approved on revision cycle
      this.save(true);
    }
    else {
      this.refresh();
    }
  }

  protected parseDrawingNo(drawing_no: string): string[]|null {
    // check filename for standard drawing no. format
    // - (e.g.: EE-05-01-1_drawing_title.pdf)
    //const re = /(..)-(\d\d)-(\d\d)(?:-(.{1,2}))?_(.*)(?:\.(\w+)$)/;
    const re = /(..)-(\d\d)-(\d\d)(?:-(.{1,2}))?(?:_(.*)(?:\.(\w+)$))?/;
    const seg = drawing_no.match(re);
    // seg[0] = whole file name
    // seg[1] = system name
    // seg[2] = subsystem id (01-13)
    // seg[3] = page (01-99)
    // seg[4] = supplement code (01-99) - optional (undefined if not avaialble)
    // seg[5] = name part of the file name
    // seg[6] = file extension
    if ( seg === null ) {
      return null;
    }

    if ( parseInt(seg[2]) < 0  ) seg[2] = '00';
    if ( parseInt(seg[2]) > 13 ) seg[2] = '13';

    if ( parseInt(seg[3]) < 1  ) seg[3] = '01';
    if ( parseInt(seg[3]) > 99 ) seg[3] = '99';

    if ( seg[4] && parseInt(seg[4]) < 1  ) seg[4] = '01';
    if ( seg[4] && parseInt(seg[4]) > 99 ) seg[4] = '99';

    return seg;
  }

  /**
   * dropzone interfaces
   */
  public async onFileAdded(file: DZFile) {

    if ( this.dropzone.dropzone.files.length > 1 ) {
      await this.ui.alert(`Maximum number of files exceeded! Cannot add <span class="fst-italic fw-lighter text-break">${file.name}</span>.`);
      this.dropzone.removeFile(file);;
      return;
    }

    if ( ! await this.ui.confirm(`Add file <span class="fst-italic fw-lighter text-break">${file.name}</span>?`) ) {
      this.dropzone.removeFile(file);
      this.drawing.drawing_no = '';
      this.drawing.title = '';
      return;
    }

    file.requestParams = { 'defer-key': this.defer_key };

    const seg = this.parseDrawingNo(file.name.trim());
    // seg[0] = whole file name
    // seg[1] = system name
    // seg[2] = subsystem id (01-13)
    // seg[3] = page (01-99)
    // seg[4] = supplement code (01-99) - optional (undefined if not avaialble)
    // seg[5] = name part of the file name (optional)
    // seg[6] = file extension (optional)

    // first draft
    if ( this.firstDraft() ) {
      if ( seg === null ) {
        // non-std file name
        if ( ! await this.ui.confirm('File name is not an internal drawing number format. You will need to enter the drawing no. manually. Proceed?') ) {
          this.dropzone.removeFile(file);
          return;
        }
        this.drawing.drawing_no = '';
        this.drawing.title = '';
      }
      else {
        // std file name
        // always replace the drawing no and title for first draft
        this.drawing.drawing_no = seg.slice(1, 5).filter( s => s && s.trim().length > 0 ).join('-');
        this.drawing.title = seg[5];
      }
    }

    // set revision to draft, if previouse is approved
    if ( this.drawing.status == 'approved' ) {
      /*
      const dn = this.parseDrawingNo(this.drawing.drawing_no);
      if ( dn === null ) {
        if ( seg !== null && ! await this.ui.confirm('File name does not match the drawing no. and title. proceed?') ) {
          this.dropzone.removeFile(file);
          return;
        }
      }
      else {
        if ( seg === null ) {
          await this.ui.alert('File name does not match the drawing no. and title. Please recheck the file.');
          this.dropzone.removeFile(file);
          return;
        }
        else {
          // std drawing no - compare drawing no and title with existing, reject if not match
          const drawing_no = seg.slice(1, 5).filter( s => s && s.trim().length > 0 ).join('-');
          if ( drawing_no !== this.drawing.drawing_no || seg[5].trim() != this.drawing.title ) {
            await this.ui.alert('File name does not match the drawing no. and title. Please recheck the file.');
            this.dropzone.removeFile(file);
            return;
          }
        }
      }
      */
      this.drawing.status = 'draft'; // advance to revision draft (check together with number of attached drawings)
      this.refreshAfterUpload = true;
      this.save(true);
    }
    this.markDirty();
  }

  public async onFileRemoved(file: DZFile) {
    if ( this.revisionDraft() ) {
      this.drawing.status = 'approved'; // rollback to approved on revision cycle
    }
  }

  public async onFileUploadSuccess(event: DZSuccess) {
    const deferKey = event.file.requestParams && event.file.requestParams['defer-key']; // extract piggy back defer key

    // create new drawing
    const drawing = await this.server.create('drawings', {
      //title: ( this.drawing.description && this.drawing.description.length > 0 ) ?  this.drawing.description : ".",
      title: this.drawing.title || undefined,
      attachment_defer_key: deferKey
    })
    .then( (o: object_t) => {
      return this.api.createDrawing(o);
    });

    // attach to Project Drawing
    /** @todo - revise according to new model */
    await this.server.request('projlib.proj_drawing.attach', {
      id: this.drawing.id,
      drawing_id: drawing.id
    }, {
      reviewer_id: this.reviewer_id,
      approver_id: this.approver_id
    });

    if ( this.drawing.status == 'approved' ) {
      // advance state to revision draft if current state is approved
      this.drawing.status = 'draft';
      this.refresh();
    }
    else if ( this.refreshAfterUpload ) {
      this.refresh();
    }
  }

  /**
   * generate drawing lists for this project
   */
  public generateDrawingsList() {
    // @TODO - add another page?
  }

}
